import {
	Box,
	Button,
	Card,
	CardActionArea,
	CardActions,
	CardContent,
	Collapse,
	Container,
	Divider,
	Radio,
	Stack,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Renderer } from '../common/components/Renderer';
import { useOrganizationConfigQuery, useSetMyCompetenciesMutation } from '../common/graphql/types-and-hooks';
import { useSnackbar } from '../common/hooks/useSnackbar';
import { SnackbarType } from '../common/providers/SnackbarProvider';
import { plural } from '../common/util/format/plural';
import { OnboardingPrimaryButton } from '../features/onboarding/OnboardingPrimaryButton';
import { OnboardingSecondaryButton } from '../features/onboarding/OnboardingSecondaryButton';
import {
	OnboardingBody1Typography,
	OnboardingBody2Typography,
	OnboardingHeadingTypography,
	OnboardingSubHeadingTypography,
} from '../features/onboarding/OnboardingTypography';
import theme from '../styles/theme';
import { AvailableRoutes } from './routeConfig';

export default function OnboardingFocusAreaSelectPage(): JSX.Element {
	const navigate = useNavigate();
	const { addSnackbar } = useSnackbar();
	const { data, isLoading, error } = useOrganizationConfigQuery();

	const setMyCompetenciesMutation = useSetMyCompetenciesMutation({
		onSuccess: () => {
			navigate({
				pathname: AvailableRoutes.OnboardingStartRequestingFeedback,
			});
		},
		onError: (error: any) => {
			console.error(error);
			addSnackbar({
				message: 'Error setting competencies! Please try again.',
				type: SnackbarType.Error,
			});
		},
	});

	const competencies = data?.organizationConfig.competencies;
	const [readMore, setReadMore] = useState(Array(competencies?.length).fill(false));
	const [selectedRadios, setSelectedRadios] = useState<number[]>([]);

	function handleRadioSelection(index: number) {
		if (selectedRadios.includes(index)) {
			setSelectedRadios(selectedRadios.filter((i) => i !== index));
		} else if (selectedRadios.length < 2) {
			setSelectedRadios([...selectedRadios, index]);
		}
	}

	function handleReadMore(index: number) {
		const newReadMore = [...readMore];
		newReadMore[index] = !newReadMore[index];
		setReadMore(newReadMore);
	}

	function handleSelectFocusAreaCard() {
		setMyCompetenciesMutation.mutate({
			payload: {
				competencies: selectedRadios.map((index) => competencies![index]._id),
			},
		});
	}

	function handleSkipForNow() {
		navigate({
			pathname: AvailableRoutes.OnboardingStartRequestingFeedback,
		});
	}

	return (
		<Container sx={{ position: 'relative' }} maxWidth={'md'}>
			<Box mb={3} mt={'28px'}>
				<OnboardingHeadingTypography pb={'10px'}>
					Select 1-2 areas where you want to focus
				</OnboardingHeadingTypography>
				<OnboardingBody1Typography color={theme.blueGray[100]}>
					You could already be strong in an area and want to assess and develop your strength. Or you could
					choose a focus area where you think you want to grow and improve.
				</OnboardingBody1Typography>
			</Box>
			<Renderer data={data} isLoading={isLoading} error={error}>
				<Stack spacing={'12px'}>
					{competencies?.map((competency, index) => (
						<Card
							key={competency._id}
							sx={{
								borderRadius: '12px',

								border: selectedRadios.includes(index)
									? `2px solid ${theme.palette.secondary.main}`
									: 'none',
							}}
						>
							<CardActionArea onClick={() => handleRadioSelection(index)}>
								<CardContent>
									<Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
										<OnboardingSubHeadingTypography color={theme.blueGray[900]}>
											{competency.title}
										</OnboardingSubHeadingTypography>
										<Radio color='secondary' checked={selectedRadios.includes(index)} />
									</Stack>
									<OnboardingBody2Typography color={theme.blueGray[800]}>
										{competency.subtitle}
									</OnboardingBody2Typography>
								</CardContent>
							</CardActionArea>
							<Divider />
							<CardActions>
								<Stack>
									<Box>
										<Button color='secondary' onClick={() => handleReadMore(index)}>
											{readMore[index] ? 'Read Less' : 'Read More'}
										</Button>
									</Box>

									<Collapse in={readMore[index]} timeout='auto' unmountOnExit>
										{competency.details?.map((detail) => (
											<OnboardingBody2Typography
												key={detail}
												color={theme.blueGray[800]}
												px={1}
												py={0.5}
											>
												{detail}
											</OnboardingBody2Typography>
										))}
									</Collapse>
								</Stack>
							</CardActions>
						</Card>
					))}
					<Box height={'150px'} />
				</Stack>
			</Renderer>
			<Box position='fixed' bottom={0} left={0} right={0} bgcolor={theme.blueGray[900]}>
				<Container maxWidth={'md'}>
					<OnboardingPrimaryButton
						onClick={handleSelectFocusAreaCard}
						buttonText={
							selectedRadios.length === 0
								? 'Select a focus area'
								: `CONTINUE WITH (${selectedRadios.length}) SELECTION${plural(selectedRadios.length)}`
						}
						sx={{ mt: 3 }}
						disabled={selectedRadios.length === 0}
					/>
					<OnboardingSecondaryButton onClick={handleSkipForNow} buttonText='SKIP FOR NOW' />
				</Container>
			</Box>
		</Container>
	);
}
