import { Box, Button, Card, CardContent, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { Competency } from '../../../common/graphql/types-and-hooks';
import { dayMonthDay, getDaysSinceNow, time } from '../../../common/util/format/date';
import { fullNameOrEmail } from '../../../common/util/format/name';
import { ResponsePageString, useResponse } from '../../../pages/Response';

type ContextItem = {
	title: string;
	body: string;
	index?: number;
	shouldRender?: boolean;
};

export function Context(): JSX.Element {
	const { request, navigate } = useResponse();

	const contextItems: ContextItem[] = [
		{
			title: 'You were recently in this meeting together:',
			body: `Title: ${request.event?.title}
			When: ${dayMonthDay(request.event?.start)} (${getDaysSinceNow(request.event?.start)}) from ${time(
				request.event?.start,
			)} to ${time(request.event?.end)}
			Organizer: ${request.event?.organizerEmail}
			Number of People: ${request.event?.attendeeUsers.length}`,
			shouldRender: !!request.event,
		},
		{
			title: `You recently shared a moment with ${fullNameOrEmail(request.requestor)}.`,
			body: `Context: ${request.moment}`,
			shouldRender: !!request.moment,
		},
		{
			title: `${request.requestor.firstName}'s role was: `,
			body: request.role.name + ' - ' + request.role.description,
		},
		{
			title: `${request.requestor.firstName} has requested feedback on these areas:`,
			body: renderCompetenciesString(request.competencies),
		},
		{
			title: `${request.requestor.firstName} wanted to share a little more context:`,
			body: request.context as string,
			shouldRender: !!request.context,
		},
	];

	function renderCompetenciesString(competencies: Competency[]): string {
		return competencies?.map((c) => c.title).join(' and ');
	}

	function renderContextCard(contextItem: ContextItem): JSX.Element | null {
		if (contextItem.shouldRender === false) return null;
		return (
			<Card key={contextItem.index} sx={{ backgroundColor: '#ECEFF1' }}>
				<CardContent>
					<Typography variant='h4' gutterBottom>
						{contextItem.title}
					</Typography>
					<Typography variant='body1' color='#546E7A'>
						{contextItem.body}
					</Typography>
				</CardContent>
			</Card>
		);
	}

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
			<Stack spacing={2}>
				{contextItems?.map((contextItem, i) => renderContextCard({ ...contextItem, index: i }))}
			</Stack>
			<Button
				onClick={() => navigate(ResponsePageString.QUESTION_SELECT)}
				color='secondary'
				variant='contained'
				fullWidth
			>
				GIVE FEEDBACK
			</Button>
		</Box>
	);
}
