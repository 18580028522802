import { format, formatDistance } from 'date-fns';

// e.g Feb 24, 2023
export function monthDayYear(date: string | Date | undefined): string {
	if (!date) {
		return 'N/A';
	}
	const formatString = 'MMM d, yyyy';
	return format(new Date(date), formatString);
}

// e.g Mon Feb 24
export function dayMonthDay(date: string | Date | undefined): string {
	if (!date) {
		return 'N/A';
	}
	const formatString = 'eee MMM d';
	return format(new Date(date), formatString);
}

// e.g 9:00 am
export function time(date: string | Date | undefined): string {
	if (!date) {
		return 'N/A';
	}
	const formatString = 'h:mm bbb';
	return format(new Date(date), formatString);
}

export function getDaysSinceNow(date: string | Date | undefined): string {
	if (!date) return 'N/A';
	const now = new Date();
	return formatDistance(new Date(date), now) + ' ago';
}
