import { Link, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { InchE } from '../../../common/components/Inch-e';
import { useResponse } from '../../../pages/Response';
import { AvailableRoutes } from '../../../pages/routeConfig';

export default function Success() {
	const navigate = useNavigate();
	const { request } = useResponse();
	return (
		<>
			<Typography variant='h1' sx={{ textAlign: 'center' }}>
				Thanks so much! Your feedback is on it&apos;s way to {request.requestor.firstName}
			</Typography>
			<Link
				component='button'
				sx={{ textAlign: 'center', marginY: '10px' }}
				onClick={() => navigate(AvailableRoutes.Dashboard)}
			>
				Go Home
			</Link>
			<InchE />
		</>
	);
}
