import { Box, Card, CardActionArea, CardContent, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { UserAvatar } from '../../../../common/components/UserAvatar';
import { Request } from '../../../../common/graphql/types-and-hooks';
import { meetingOrMomentTitleShort } from '../../../../common/util/format/meetingOrMoment';
import { firstNameOrEmail } from '../../../../common/util/format/name';
import { AvailableRoutes } from '../../../../pages/routeConfig';
import { FeedbackToMeCardBadge } from './FeedbackToMeCardBadge';

type FeedbackToMeCardProps = {
	request: Request;
};

export function FeedbackToMeCard({ request }: FeedbackToMeCardProps): JSX.Element {
	const navigate = useNavigate();
	return (
		<Card>
			<CardActionArea
				disabled={!request.response}
				onClick={() => navigate(`${AvailableRoutes.ViewResponse}/${request._id}`)}
			>
				<CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
					<Box sx={{ display: 'flex', gap: '10px' }}>
						<UserAvatar user={request.responder} />
						<Box>
							<Typography variant='body2'>{firstNameOrEmail(request.responder)}</Typography>
							<Typography variant='body1'>{meetingOrMomentTitleShort(request)}</Typography>
						</Box>
					</Box>

					<Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
						<FeedbackToMeCardBadge newLabelText='NEW' request={request} />
					</Box>
				</CardContent>
			</CardActionArea>
		</Card>
	);
}
