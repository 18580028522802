import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { RequestPageString } from '../../../pages/Request';
import { AvailableRoutes } from '../../../pages/routeConfig';
import { MeetingOrMoment } from '../MeetingOrMomentSelect/MeetingOrMomentSelect';
import { RequestFlow } from '../PersonSelect/PersonSelect';
import MeetingOrPersonButton from './MeetingOrPersonSelectButton';

export function MeetingOrPersonSelect(): JSX.Element {
	const navigate = useNavigate();
	return (
		<Box sx={meetingOrPersonStyles}>
			<MeetingOrPersonButton
				onClick={() =>
					navigate(
						`${AvailableRoutes.Request}/${RequestPageString.MEETING}?view=${MeetingOrMoment.MEETING}&flow=${RequestFlow.MeetingFirst}`,
					)
				}
			>
				<img src='/meeting.png' height={20} width={20} alt='meeting icon' />
				<Typography sx={{ marginLeft: '10px', color: 'black', textTransform: 'none' }} variant='body1'>
					Meeting or Moment
				</Typography>
			</MeetingOrPersonButton>

			<MeetingOrPersonButton
				onClick={() =>
					navigate(`${AvailableRoutes.Request}/${RequestPageString.PERSON}?flow=${RequestFlow.PersonFirst}`)
				}
			>
				<img src='/businessman.png' height={20} width={20} alt='businessman icon' />
				<Typography sx={{ marginLeft: '10px', color: 'black', textTransform: 'none' }} variant='body1'>
					Person
				</Typography>
			</MeetingOrPersonButton>
		</Box>
	);
}

const meetingOrPersonStyles = {
	display: 'flex',
	flexDirection: 'column',
	gap: '5px',
};
