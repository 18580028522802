import { Box, CardActionArea } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { uniqBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Renderer } from '../../../common/components/Renderer';
import {
	Competency,
	useGetUserByAuth0IdQuery,
	useOrganizationConfigQuery,
} from '../../../common/graphql/types-and-hooks';
import { RequestPageString, useRequest } from '../../../pages/Request';
import { AvailableRoutes } from '../../../pages/routeConfig';

export function CompetencySelect(): JSX.Element {
	const { data, isLoading, error } = useOrganizationConfigQuery();
	const { data: user, isLoading: isUserLoading } = useGetUserByAuth0IdQuery();
	const navigate = useNavigate();
	const [request, setRequest] = useRequest();

	const [recommendedFocusAreas, setRecommendedFocusAreas] = useState<Competency[]>([]);
	const [allOtherFocusAreas, setAllOtherFocusAreas] = useState<Competency[]>([]);

	useEffect(() => {
		if (!user || !data) {
			return;
		}

		const userFocusAreas = user?.getUserByAuth0Id.competencies;
		const roleBasedFocusAreas = data?.organizationConfig.organization.roles.find((r) => request.role._id === r._id)
			?.competencies!;
		const uniqueList = uniqBy([...userFocusAreas, ...roleBasedFocusAreas], (c) => c._id);
		setRecommendedFocusAreas(uniqueList);
		setAllOtherFocusAreas(
			data.organizationConfig.competencies.filter((all) => !uniqueList.some((rec) => rec._id === all._id)),
		);
	}, [data, user, request]);

	function handleCompetencySelection(competency: Competency): void {
		setRequest({ ...request, competencies: [competency] });
		navigate(`${AvailableRoutes.Request}/${RequestPageString.RECAP}`);
	}

	function renderCompetencyCard(competency: Competency): JSX.Element {
		return (
			<Card key={competency._id} onClick={() => handleCompetencySelection(competency)}>
				<CardActionArea>
					<CardContent>
						<Typography variant='h5' component='div'>
							{competency.title}
						</Typography>
						<Typography sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
							{competency.subtitle}
						</Typography>
					</CardContent>
				</CardActionArea>
			</Card>
		);
	}

	return (
		<Renderer isLoading={isLoading || isUserLoading} data={data} error={error}>
			<>
				<Box>
					<Typography variant='h4'>Suggested</Typography>
					<Stack sx={{ marginTop: '5px' }} spacing={2}>
						{recommendedFocusAreas.map((c) => renderCompetencyCard(c))}
					</Stack>
				</Box>
				<Box sx={{ marginTop: '10px' }}>
					<Typography variant='h4'>All Focus Areas</Typography>
					<Stack sx={{ marginTop: '5px' }} spacing={2}>
						{allOtherFocusAreas.map((c) => renderCompetencyCard(c))}
					</Stack>
				</Box>
			</>
		</Renderer>
	);
}
