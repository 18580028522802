import { Box, Card, CardActionArea, CardContent, TextField, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '../../../common/components/LoadingButton';
import { Competency, CreateRequestInput, useCreateRequestMutation } from '../../../common/graphql/types-and-hooks';
import { useSnackbar } from '../../../common/hooks/useSnackbar';
import { SnackbarType } from '../../../common/providers/SnackbarProvider';
import { meetingOrMomentTitle } from '../../../common/util/format/meetingOrMoment';
import { useRequest } from '../../../pages/Request';
import { AvailableRoutes } from '../../../pages/routeConfig';

type RecapItem = { title: string; body?: string; index?: number };

export function Recap(): JSX.Element {
	const navigate = useNavigate();
	const { addSnackbar } = useSnackbar();
	const [request, setRequest] = useRequest();
	const mutation = useCreateRequestMutation({
		onMutate: () => {
			addSnackbar({
				message: 'Sending request...',
				type: SnackbarType.Loading,
			});
		},
		onSuccess: () => {
			addSnackbar({
				message: 'Feedback request created!',
				type: SnackbarType.Success,
			});
			navigate(AvailableRoutes.Dashboard);
		},
		onError: (error: any) => {
			console.error(error);
			addSnackbar({
				message: 'Error creating request! Please try again.',
				type: SnackbarType.Error,
			});
		},
	});

	const recapItems: RecapItem[] = [
		{
			title: 'I would like feedback from:',
			body: `${request.responder.firstName} ${request.responder.lastName}`,
		},
		{
			title: `On this ${request.event ? 'event' : 'moment'}:`,
			body: meetingOrMomentTitle(request),
		},
		{ title: 'Where I had the role of:', body: request.role.name },
		{
			title: `Specifically, I'd like their feedback on these areas:`,
			body: renderCompetenciesString(request.competencies),
		},
	];

	function renderCompetenciesString(competencies: Competency[]): string {
		return competencies?.map((c) => c.title).join(', ');
	}

	function renderRecapCard(recapItem: RecapItem): JSX.Element {
		return (
			<Card key={recapItem.index} sx={{ backgroundColor: '#ECEFF1' }}>
				<CardActionArea>
					<CardContent>
						<Typography variant='h4' gutterBottom>
							{recapItem.title}
						</Typography>
						<Typography variant='body1' color='#546E7A'>
							{recapItem.body}
						</Typography>
					</CardContent>
				</CardActionArea>
			</Card>
		);
	}

	function onSubmit() {
		const createRequestInput: CreateRequestInput = {
			competencies: request.competencies.map((c) => c._id),
			...(request.event && { event: request.event }),
			...(request.moment && { moment: request.moment }),
			responder: request.responder._id,
			role: request.role._id,
			context: request.context,
		};
		mutation.mutate({ payload: createRequestInput });
	}

	return (
		<Box>
			<Stack spacing={2}>
				{recapItems?.map((recapItem, i) => renderRecapCard({ ...recapItem, index: i }))}
				<Box>
					<Typography variant='h4' color='#455A64'>
						Give additional context for why you would like this feedback (optional)
					</Typography>
					<Typography variant='body1' color='#455A64'>
						This note will go to your feedback-giver along with your request.
					</Typography>
					<TextField
						value={request.context}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							setRequest({ ...request, context: event?.target?.value });
						}}
						placeholder="I'm asking you for feedback because..."
						multiline
						fullWidth
						rows={4}
					/>
				</Box>
				<LoadingButton
					loading={mutation.isLoading}
					onClick={() => onSubmit()}
					color='secondary'
					variant='contained'
					fullWidth
				>
					SEND REQUEST TO {request?.responder?.firstName}
				</LoadingButton>
			</Stack>
		</Box>
	);
}
