import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Chip, Typography } from '@mui/material';
import { Request } from '../../../../common/graphql/types-and-hooks';
import { getDaysSinceNow } from '../../../../common/util/format/date';

export function FeedbackToMeCardBadge(props: { request: Request; newLabelText: string }) {
	const { request, newLabelText } = props;
	if (request.response) {
		if (request.response.viewedAt) {
			return (
				<>
					<Typography variant='overline'>Viewed {getDaysSinceNow(request.createdAt)}</Typography>
					<ArrowForwardIcon />
				</>
			);
		} else {
			return (
				<>
					<Chip sx={{ color: '#09686B', backgroundColor: '#09686b5c' }} label={newLabelText} />
					<ArrowForwardIcon />
				</>
			);
		}
	} else {
		return (
			<>
				<Chip sx={{ color: '#F77635', backgroundColor: '#f7763533' }} label='REQUESTED' />
				<ArrowForwardIcon />
			</>
		);
	}
}
