import { Box, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { BackButton } from '../common/components/BackButton';
import { Request } from '../common/graphql/types-and-hooks';
import { lastPathOfRoute } from '../common/util/route';
import { cardBodyStyles, pageContentStyles, pageStyles } from '../styles/common';
import { AvailableRoutes } from './routeConfig';

export enum RequestPageString {
	START = 'start',
	PERSON = 'person',
	MEETING = 'meeting',
	ROLE = 'role',
	FOCUS_AREA = 'focus-area',
	RECAP = 'recap',
	SUCCESS = 'success',
}

type RequestPageConfig = {
	id: RequestPageString;
	title: string;
	subtitle?: string;
};

const pages: RequestPageConfig[] = [
	{
		id: RequestPageString.START,
		title: 'Start with a...',
	},
	{
		id: RequestPageString.PERSON,
		title: 'Who do you want feedback from?',
	},
	{
		id: RequestPageString.ROLE,
		title: 'What was your role in this meeting?',
	},
	{
		id: RequestPageString.MEETING,
		title: 'Select a...',
	},
	{
		id: RequestPageString.FOCUS_AREA,
		title: 'Which area do you want feedback on?',
		subtitle: 'Choose one, either from your growth areas or the list suggested based on your meeting role',
	},
	{
		id: RequestPageString.RECAP,
		title: `Great! Here's a recap of your request`,
	},
	{
		id: RequestPageString.SUCCESS,
		title: `Nice! You're just inches away from great feedback.`,
	},
];

type RequestFormContext = [Request, React.Dispatch<React.SetStateAction<Request>>];

export function useRequest() {
	return useOutletContext<RequestFormContext>();
}

export default function RequestPage(): JSX.Element {
	const [request, setRequest] = useState<Request>({} as Request);
	const startPage = pages.find((p) => p.id === RequestPageString.START);
	const [pageLookup, setPageLookup] = useState<RequestPageConfig>(startPage!);
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		function lookupCurrentPageToRender(): RequestPageConfig | undefined {
			const page = lastPathOfRoute(location.pathname);
			if (
				![RequestPageString.START, RequestPageString.MEETING, RequestPageString.PERSON].includes(
					page as RequestPageString,
				) &&
				isEmpty(request)
			) {
				navigate(`${AvailableRoutes.Request}/${RequestPageString.START}`);
				return;
			}
			const foundPage = pages.find((p) => p.id === page);
			if (!foundPage) {
				navigate(`${AvailableRoutes.Request}/${RequestPageString.START}`);
				return;
			}
			setPageLookup(foundPage!);
		}
		lookupCurrentPageToRender();
	}, [location, request, navigate]);

	return (
		<Box sx={pageStyles}>
			<Box sx={pageContentStyles}>
				<Box sx={{ width: '100%' }}>
					<BackButton></BackButton>

					<Typography variant='h1' sx={{ color: '#FFFFFF' }}>
						{pageLookup?.title}
					</Typography>
					<Typography variant='body2' sx={{ color: '#CFD8DC' }}>
						{pageLookup?.subtitle}
					</Typography>
				</Box>
				<Box sx={cardBodyStyles}>
					<Outlet context={[request, setRequest]}></Outlet>
				</Box>
			</Box>
		</Box>
	);
}
