import { Box, Slider, styled, Typography } from '@mui/material';
import { CreateEvaluationInput } from '../../../common/graphql/types-and-hooks';
import { EvaluationKeyValuePair } from '../../../pages/Response';

export type FeedbackSliderProps = {
	evaluation: CreateEvaluationInput;
	score: number;
	updateEvaluation: (evaluationQuestionId: string, change: EvaluationKeyValuePair) => void;
};

export function FeedbackSlider({ updateEvaluation, evaluation }: FeedbackSliderProps): JSX.Element {
	function onCommitted(v: number) {
		updateEvaluation(evaluation.question, { score: v });
	}

	const FeedbackSlider = styled(Slider)(() => ({
		height: 3,
		padding: '15px 0',
		'& .MuiSlider-track': {
			border: 'none',
		},
		'& .MuiSlider-rail': {
			opacity: 0.5,
			backgroundColor: '#bfbfbf',
		},
		'& .MuiSlider-mark': {
			backgroundColor: '#bfbfbf',
			height: 8,
			width: 1,
			'&.MuiSlider-markActive': {
				opacity: 1,
				backgroundColor: 'currentColor',
			},
		},
	}));
	return (
		<>
			<FeedbackSlider
				aria-label='Score'
				color='secondary'
				defaultValue={evaluation.score}
				onChangeCommitted={(e, v) => onCommitted(v as number)}
				step={1}
				marks
				min={-3}
				max={3}
			/>
			<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
				<Typography variant='body1'>Too Little</Typography>
				<Typography variant='body1'>Just Right</Typography>
				<Typography variant='body1'>Too Much</Typography>
			</Box>
		</>
	);
}
