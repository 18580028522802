import { Outlet } from 'react-router-dom';
import { NavBar } from '../features/app-shell/NavBar';

export function OnboardingShell(): JSX.Element {
	return (
		<>
			<NavBar />
			<Outlet />
		</>
	);
}
