import { Box, Container } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { NavBar } from '../features/app-shell/NavBar';

export function AppShell(): JSX.Element {
	return (
		<>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					height: '100vh',
				}}
			>
				<NavBar></NavBar>
				<Container component='main' sx={{ flexGrow: 1 }} maxWidth='md'>
					<Outlet></Outlet>
				</Container>
			</Box>
		</>
	);
}
