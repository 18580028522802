import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Collapse, IconButton, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { Request } from '../../common/graphql/types-and-hooks';
import theme from '../../styles/theme';

type RequestContextDrawerProps = {
	request: Request;
};
export function RequestContextDrawer({ request }: RequestContextDrawerProps) {
	const [open, setOpen] = useState(false);

	return (
		<Box
			bgcolor={theme.blueGray[800]}
			borderRadius={'12px'}
			p={2}
			width={'100%'}
			onClick={() => setOpen(!open)}
			sx={{ cursor: 'pointer' }}
		>
			<Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
				<Typography variant='h4' color={'#FFF'}>
					Context from {request.requestor.firstName}
				</Typography>
				{open ? (
					<IconButton sx={{ color: '#FFF' }} size='large'>
						<ExpandLessIcon fontSize='inherit' />
					</IconButton>
				) : (
					<IconButton sx={{ color: '#FFF' }} size='large'>
						<ExpandMoreIcon fontSize='inherit' />
					</IconButton>
				)}
			</Stack>
			<Collapse in={open}>
				<Typography variant='h5' color={theme.blueGray[100]} mt={2}>
					{request.context}
				</Typography>
			</Collapse>
		</Box>
	);
}
