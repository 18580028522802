import { Box, Slider, styled, Typography } from '@mui/material';

export type ReadOnlyFeedbackSliderProps = {
	score: number;
};

export function ReadOnlyFeedbackSlider({ score }: ReadOnlyFeedbackSliderProps): JSX.Element {
	const FeedbackSlider = styled(Slider)(() => ({
		height: 3,
		padding: '15px 0',
		'& .MuiSlider-track': {
			border: 'none',
		},
		'& .MuiSlider-rail': {
			opacity: 0.5,
			backgroundColor: '#bfbfbf',
		},
		'& .MuiSlider-mark': {
			backgroundColor: '#bfbfbf',
			height: 8,
			width: 1,
			'&.MuiSlider-markActive': {
				opacity: 1,
				backgroundColor: 'currentColor',
			},
		},
	}));
	return (
		<>
			<FeedbackSlider
				aria-label='Score'
				disabled
				color='secondary'
				defaultValue={0}
				value={score}
				step={1}
				marks
				min={-3}
				max={3}
			/>
			<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
				<Typography variant='body1'>Too Little</Typography>
				<Typography variant='body1'>Just Right</Typography>
				<Typography variant='body1'>Too Much</Typography>
			</Box>
		</>
	);
}
