import { Box, Stack } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EmptyInchE } from '../../../common/components/EmptyInchE';
import { useGetUserByAuth0IdQuery } from '../../../common/graphql/types-and-hooks';
import { AvailableRoutes } from '../../../pages/routeConfig';
import theme from '../../../styles/theme';
import { FocusAreaCard } from './FocusAreaCard';

export function FocusAreas(): JSX.Element {
	const navigate = useNavigate();
	const { data: user } = useGetUserByAuth0IdQuery();

	const [activeCard, setActiveCard] = useState(0);

	if (!user) return <></>;

	if (!user.getUserByAuth0Id.competencies.length) {
		return (
			<EmptyInchE
				title='No focus areas selected yet'
				body='Tell your team where you want to get better'
				buttonProps={{
					text: 'Select focus areas',
					onClick: () => navigate(AvailableRoutes.FocusAreas),
				}}
			/>
		);
	}

	return (
		<Stack spacing={1.5}>
			{user.getUserByAuth0Id.competencies.map(
				(competency, index) =>
					index === activeCard && (
						<FocusAreaCard key={index} title={competency.title} details={competency.subtitle} />
					),
			)}
			{user.getUserByAuth0Id.competencies.length > 1 && (
				<Stack direction={'row'} spacing={2} justifyContent={'center'}>
					{user.getUserByAuth0Id.competencies.map((_, index) => (
						<Box
							key={index}
							width={'90px'}
							height={'12px'}
							bgcolor={activeCard === index ? '#BAC53C' : theme.blueGray[700]}
							borderRadius={'100px'}
							sx={{ cursor: 'pointer' }}
							onClick={() => setActiveCard(index)}
						/>
					))}
				</Stack>
			)}
		</Stack>
	);
}
