import { Button } from '@mui/material';

type MeetingOrPersonSelectButtonProps = {
	onClick: () => void;
	children: JSX.Element[];
};

export default function MeetingOrPersonSelectButton({
	children,
	onClick,
}: MeetingOrPersonSelectButtonProps): JSX.Element {
	return (
		<Button variant='outlined' color='secondary' fullWidth sx={buttonStyles} onClick={() => onClick()}>
			{children}
		</Button>
	);
}

const buttonStyles = {
	justifyContent: 'flex-start',
	padding: '16px',
	textAlign: 'left',
};
