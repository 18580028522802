import { Card, CardActionArea, CardActions, CardContent, Collapse } from '@mui/material';
import { useState } from 'react';

export type ExpandableCardProps = {
	cardActionAreaOnClick?: () => void;
	mainSection: JSX.Element;
	clickToExpandSection: JSX.Element;
	expandSection: JSX.Element;
};
export function ExpandableCard({
	cardActionAreaOnClick,
	mainSection,
	expandSection,
	clickToExpandSection,
}: ExpandableCardProps): JSX.Element {
	const [expanded, setExpanded] = useState(false);

	function conditionalActionArea() {
		if (cardActionAreaOnClick) {
			return (
				<CardActionArea onClick={() => cardActionAreaOnClick()}>
					<CardContent>{mainSection}</CardContent>
				</CardActionArea>
			);
		} else {
			return <CardContent>{mainSection}</CardContent>;
		}
	}

	return (
		<Card>
			{conditionalActionArea()}
			<CardActions sx={{ borderTop: `1px solid rgba(0, 0, 0, .3)` }} onClick={() => setExpanded(!expanded)}>
				{clickToExpandSection}
			</CardActions>
			<Collapse in={expanded} timeout='auto' unmountOnExit>
				<CardContent sx={{ paddingTop: '0px' }}>{expandSection}</CardContent>
			</Collapse>
		</Card>
	);
}
