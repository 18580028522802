import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Chip, Typography } from '@mui/material';
import { Request } from '../../../../common/graphql/types-and-hooks';
import { getDaysSinceNow } from '../../../../common/util/format/date';

type FeedbackToMeCardBadgeProps = {
	request: Request;
};
export function FeedbackFromMeCardBadge({ request }: FeedbackToMeCardBadgeProps): JSX.Element {
	if (request.response) {
		if (request.response.viewedAt) {
			return (
				<>
					<Typography variant='overline'>Viewed {getDaysSinceNow(request.response.viewedAt)}</Typography>
					<ArrowForwardIcon />
				</>
			);
		} else {
			return (
				<>
					<Chip sx={{ color: '#09686B', backgroundColor: '#09686b5c' }} label='NOT READ YET' />
					<ArrowForwardIcon />
				</>
			);
		}
	} else {
		return (
			<>
				<Chip sx={{ color: '#F77635', backgroundColor: '#f7763533' }} label='REQUESTED' />
				<ArrowForwardIcon />
			</>
		);
	}
}
