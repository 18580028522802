import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BackButton } from '../common/components/BackButton';
import { Renderer } from '../common/components/Renderer';
import {
	useMarkResponseAsHelpfulMutation,
	useMarkResponseAsReadMutation,
	useRequestQuery,
} from '../common/graphql/types-and-hooks';
import { monthDayYear } from '../common/util/format/date';
import { firstNameOrEmail } from '../common/util/format/name';
import { ViewResponseCard } from '../features/view-response/ViewResponseCard';
import { cardBodyStyles, pageContentStyles, pageStyles } from '../styles/common';
import { AvailableRoutes } from './routeConfig';

export default function ViewResponsePage(): JSX.Element {
	const navigate = useNavigate();
	const { requestId } = useParams();
	const { data: request, isLoading, isError, refetch } = useRequestQuery({ _id: requestId as string });
	const markAsHelpfulMutation = useMarkResponseAsHelpfulMutation();
	const markAsReadMutation = useMarkResponseAsReadMutation();
	const { user, isLoading: authLoading } = useAuth0();

	useEffect(() => {
		if (isLoading || markAsReadMutation.isLoading) return;
		async function markAsRead() {
			await markAsReadMutation.mutateAsync({
				requestId: requestId!,
			});
			await refetch();
		}
		const requestIsYours = request?.request.requestor.auth0Id === user?.sub;
		if (requestIsYours && !request?.request.response?.viewedAt) {
			markAsRead();
		}
	}, [request, user, markAsReadMutation, requestId, refetch, isLoading]);

	return (
		<Box sx={pageStyles}>
			<Renderer data={request} isLoading={isLoading || authLoading} error={isError}>
				<Box sx={pageContentStyles}>
					<Box
						sx={{
							width: '100%',
							display: 'flex',
							flexDirection: 'column',
							gap: '10px',
						}}
					>
						<BackButton></BackButton>
						<Typography variant='h1' sx={{ color: '#FFFFFF' }}>
							Feedback from {firstNameOrEmail(request?.request.responder!)}
						</Typography>
						<Typography variant='body2' sx={{ color: '#CFD8DC' }}>
							{monthDayYear(request?.request?.response?.createdAt)}
						</Typography>
					</Box>
					<Box sx={cardBodyStyles}>
						<ViewResponseCard request={request?.request!}></ViewResponseCard>
						{request?.request.requestor.auth0Id === user?.sub && (
							<>
								<Typography variant='h6'>
									Was this feedback helpful? We will not share this with the{' '}
									{firstNameOrEmail(request?.request.responder!)}, it is just for our own improvement
								</Typography>
								<Button
									sx={{ marginTop: '10px' }}
									variant='contained'
									color='secondary'
									onClick={async () => {
										await markAsHelpfulMutation.mutateAsync({
											requestId: requestId!,
											helpful: true,
										});
										navigate(AvailableRoutes.Dashboard);
									}}
								>
									Yes!
								</Button>
								<Button
									sx={{ marginTop: '10px' }}
									variant='contained'
									color='primary'
									onClick={async () => {
										await markAsHelpfulMutation.mutateAsync({
											requestId: requestId!,
											helpful: false,
										});
										navigate(AvailableRoutes.Dashboard);
									}}
								>
									No...
								</Button>
							</>
						)}
					</Box>
				</Box>
			</Renderer>
		</Box>
	);
}
