import { Stack, TextField, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Renderer } from '../../../common/components/Renderer';
import { Event, useEventsForUserQuery } from '../../../common/graphql/types-and-hooks';
import { firstNameOrEmail } from '../../../common/util/format/name';
import { useRequest } from '../../../pages/Request';
import { RequestFlow } from '../PersonSelect/PersonSelect';
import { MeetingCard } from './MeetingCard';
import { MEETING_OR_MOMENT_FLOW_QUERY_STRING } from './MeetingOrMomentSelect';

export function MeetingSelect() {
	const { data, isLoading, error } = useEventsForUserQuery();
	const [request] = useRequest();
	const [searchParams] = useSearchParams();
	const [events, setEvents] = useState<Event[]>();
	const flow = searchParams.get(MEETING_OR_MOMENT_FLOW_QUERY_STRING);

	const eventsFilteredToResponderCallback = useCallback(
		function eventsFilteredToResponder(): Event[] | undefined {
			return data?.eventsForUser.filter((e) => e.attendeeUsers.some((a) => a.email === request.responder.email));
		},
		[data, request],
	);

	function onSearchInputChange(value: string): void {
		const regex = new RegExp(value, 'gi');
		const eventsToSearch =
			flow === RequestFlow.MeetingFirst ? data?.eventsForUser : eventsFilteredToResponderCallback();
		setEvents(
			eventsToSearch?.filter((event) => {
				return event.title.match(regex);
			}),
		);
	}

	useEffect(() => {
		if (flow === RequestFlow.MeetingFirst) {
			setEvents(data?.eventsForUser);
		} else {
			setEvents(eventsFilteredToResponderCallback());
		}
	}, [data?.eventsForUser, eventsFilteredToResponderCallback, flow]);

	return (
		<Renderer isLoading={isLoading} data={events} error={error}>
			<Stack spacing={2}>
				{flow === RequestFlow.MeetingFirst && <Typography variant='h3'>Meetings</Typography>}
				{flow === RequestFlow.PersonFirst && (
					<Typography variant='h3'>Meetings with {firstNameOrEmail(request.responder)}</Typography>
				)}
				<TextField
					label='Search Meetings'
					variant='filled'
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						onSearchInputChange(event.target.value);
					}}
				></TextField>
				<Stack spacing={2}>
					{events?.map((e: Event) => (
						<MeetingCard key={e.calendarProvidedId} event={e} />
					))}
					{events?.length === 0 && <Typography variant='h4'>No meetings found</Typography>}
				</Stack>
			</Stack>
		</Renderer>
	);
}
