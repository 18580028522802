import { Button, ButtonProps } from '@mui/material';
import { OnboardingButtonTypography } from './OnboardingTypography';

export type OnboardingSecondaryButtonProps = ButtonProps & {
	onClick: () => void;
	buttonText: string;
};

export function OnboardingSecondaryButton({ buttonText, onClick, ...rest }: OnboardingSecondaryButtonProps) {
	return (
		<Button
			variant='text'
			color='secondary'
			onClick={() => onClick()}
			fullWidth
			sx={{ height: '42px', mb: 3.5 }}
			{...rest}
		>
			<OnboardingButtonTypography color={'#FFF'}>{buttonText}</OnboardingButtonTypography>
		</Button>
	);
}
