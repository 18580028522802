import { Box, Container } from '@mui/material';
import { PropsWithChildren } from 'react';
import { useWindowSize } from 'react-use';

export function BottomFixedButtonWrapper({ children }: PropsWithChildren) {
	const { height: currentWindowHeightPixels } = useWindowSize();

	const documentHeightPixels = document.documentElement.scrollHeight;
	const bufferSpaceToTriggerScroll = 50;

	const isScrollable = documentHeightPixels - bufferSpaceToTriggerScroll > currentWindowHeightPixels;

	if (!isScrollable) return <>{children}</>;

	return (
		<>
			<Box height={'30px'} />
			<Box
				sx={{
					position: 'fixed',
					bottom: '0',
					left: '0',
					zIndex: 9999,
					bgcolor: '#263238',
					width: '100%',
				}}
			>
				<Container sx={{ my: 2 }}>{children}</Container>
			</Box>
		</>
	);
}
