import { Card, CardContent, Typography } from '@mui/material';
import theme from '../../../styles/theme';

type FocusAreaCardProps = {
	title: string;
	details: string;
};

export function FocusAreaCard({ title, details }: FocusAreaCardProps): JSX.Element {
	return (
		<Card>
			<CardContent>
				<Typography variant='h4' color={theme.blueGray[900]} mb={1}>
					{title}
				</Typography>
				<Typography variant='body1' color={theme.blueGray[800]}>
					{details}
				</Typography>
			</CardContent>
		</Card>
	);
}
