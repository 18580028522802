import { useAuth0 } from '@auth0/auth0-react';
import { AppBar, Box, Button, IconButton, Menu, MenuItem, Toolbar } from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { UserAvatar } from '../../common/components/UserAvatar';
import { useGetUserByAuth0IdQuery } from '../../common/graphql/types-and-hooks';
import { AvailableRoutes } from '../../pages/routeConfig';

export function NavBar(): JSX.Element {
	const { user: auth0User, logout, loginWithRedirect } = useAuth0();

	const { data: user } = useGetUserByAuth0IdQuery({}, { enabled: !!auth0User });

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	function handleMenu(event: React.MouseEvent<HTMLElement>): void {
		setAnchorEl(event.currentTarget);
	}

	function handleClose(): void {
		setAnchorEl(null);
	}

	function logoutAndCloseMenu(): void {
		handleClose();
		logout({ returnTo: window.location.origin });
	}

	return (
		<>
			<AppBar position='static'>
				<Toolbar sx={{ justifyContent: 'space-between' }}>
					<Link to={AvailableRoutes.Dashboard}>
						<Box
							style={{
								cursor: 'pointer',
								marginLeft: '8px',
								justifyContent: 'center',
							}}
						>
							<img src='/black-logo.png' height={40} width={114} alt='logo' />
						</Box>
					</Link>
					{auth0User ? (
						<Box>
							<IconButton
								size='large'
								aria-label='account of current user'
								aria-controls='menu-appbar'
								aria-haspopup='true'
								onClick={handleMenu}
								color='inherit'
							>
								<UserAvatar user={user?.getUserByAuth0Id! || auth0User} />
							</IconButton>
							<Menu
								id='menu-appbar'
								anchorEl={anchorEl}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								open={Boolean(anchorEl)}
								onClose={handleClose}
							>
								<MenuItem onClick={logoutAndCloseMenu}>Logout</MenuItem>
							</Menu>
						</Box>
					) : (
						<Button variant='outlined' color='secondary' onClick={loginWithRedirect}>
							Login
						</Button>
					)}
				</Toolbar>
			</AppBar>
		</>
	);
}
