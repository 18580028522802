import { Card, CardContent, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import Markdown from 'markdown-to-jsx';
import { Evaluation, Request } from '../../common/graphql/types-and-hooks';
import { renderQuestionSelectLabel } from '../../common/util/format/competency';
import { ReadOnlyFeedbackSlider } from './ReadOnlyFeedbackSlider';

export type ViewResponseCardProps = {
	request: Request;
};
export function ViewResponseCard({ request }: ViewResponseCardProps): JSX.Element {
	function contextLineItem(header: string, description: string): JSX.Element {
		return (
			<Box sx={{ display: 'flex', alignItems: 'center' }}>
				<Typography variant='h5'>{header}:</Typography>
				<Typography variant='body1' color='#546E7A' sx={{ marginLeft: '3px' }}>
					{description}
				</Typography>
			</Box>
		);
	}

	function renderEvaluations(): JSX.Element[] | undefined {
		return request.response?.evaluations.map((e) => renderEvaluation(e));
	}

	function renderEvaluation(evaluation: Evaluation): JSX.Element {
		return (
			<Box key={evaluation._id}>
				<Typography>
					<Markdown>
						{renderQuestionSelectLabel(evaluation.competency, 'You', evaluation.questionLookup.body)}
					</Markdown>
				</Typography>
				<ReadOnlyFeedbackSlider score={evaluation.score} />
				<Card sx={{ marginTop: '10px', marginBottom: '10px' }}>
					<CardContent sx={{ minHeight: '100px' }}>
						<Typography>{evaluation.context}</Typography>
					</CardContent>
				</Card>
			</Box>
		);
	}

	return (
		<>
			<Card sx={{ backgroundColor: '#ECEFF1' }}>
				<CardContent>
					{request.event && contextLineItem('Meeting', request.event?.title)}
					{request.moment && contextLineItem('Moment', request.moment)}
					{contextLineItem('Role', request.role.name)}
					{contextLineItem('Competencies', request.competencies.map((c) => c.title).join(' and '))}
					{request.context && contextLineItem('Context', request.context)}
				</CardContent>
			</Card>
			<Stack spacing={2} sx={{ marginTop: '10px' }}>
				{renderEvaluations()}
			</Stack>
		</>
	);
}
