const env = process.env.REACT_APP_BUILD_ENV || 'development';
export const isNotDevelopment = env !== 'development';

const commonConfigs = {
	muiLicenseKey:
		'4bc0373304a349672f01dddcda48e9e1Tz01NjUwNCxFPTE3MDMyNjE4MTEwNzEsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
};
const envBasedConfigs: InchEnvBasedConfig = {
	development: {
		api: 'http://localhost:5001/graphql',
		auth0Domain: 'staging-inch.us.auth0.com',
		auth0ClientId: 'lDP9zik5IALacluBDNMdga7GA00AEivr',
		auth0RedirectUri: 'http://localhost:3000/authed/dashboard',
		auth0Audience: 'https://api.inch.app',
	},
	production: {
		api: 'https://api.inch.app/graphql',
		auth0Domain: 'prod-inch.us.auth0.com',
		auth0ClientId: '1iVw2upZzFaxOvFx5vkNhMqtiY480LhY',
		auth0RedirectUri: 'https://inch.app/authed/dashboard',
		auth0Audience: 'https://api.inch.app',
		hotjarHJID: 3484539,
		hotjarHJSV: 6,
		sentryDsn: 'https://dfa7a419008b4fc09a11c36423e31410@o4504961747386368.ingest.sentry.io/4505213022896128',
	},
	test: {
		api: 'https://staging.api.inch.app/graphql',
		auth0Domain: 'staging-inch.us.auth0.com',
		auth0ClientId: 'lDP9zik5IALacluBDNMdga7GA00AEivr',
		auth0RedirectUri: 'https://staging.inch.app/authed/dashboard',
		auth0Audience: 'https://api.inch.app',
	},
}[env] as InchEnvBasedConfig;

type InchEnvBasedConfig = {
	api: string;
	auth0Domain: string;
	auth0ClientId: string;
	auth0RedirectUri: string;
	auth0Audience: string;
	sentryDsn?: string;
	hotjarHJID?: number;
	hotjarHJSV?: number;
};

type InchConfig = { muiLicenseKey: string } & InchEnvBasedConfig;

export const config: InchConfig = { ...envBasedConfigs, ...commonConfigs };
