import { Tab, Tabs } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { MeetingSelect } from './MeetingSelect';
import { MomentInput } from './MomentInput';

export enum MeetingOrMoment {
	MEETING = 'meeting',
	MOMENT = 'moment',
}

export const MEETING_OR_MOMENT_VIEW_QUERY_STRING: string = 'view';
export const MEETING_OR_MOMENT_FLOW_QUERY_STRING: string = 'flow';

export function MeetingOrMomentSelect(): JSX.Element {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const view = searchParams.get(MEETING_OR_MOMENT_VIEW_QUERY_STRING);
	const flow = searchParams.get(MEETING_OR_MOMENT_FLOW_QUERY_STRING);

	const handleChange = (event: React.SyntheticEvent<Element>, value: MeetingOrMoment): void => {
		navigate(
			`/authed/request/meeting?${MEETING_OR_MOMENT_VIEW_QUERY_STRING}=${
				value as MeetingOrMoment
			}&${MEETING_OR_MOMENT_FLOW_QUERY_STRING}=${flow}`,
		);
	};

	useEffect(() => {
		if (!view)
			navigate(
				`/authed/request/meeting?${MEETING_OR_MOMENT_VIEW_QUERY_STRING}=${MeetingOrMoment.MEETING}&${MEETING_OR_MOMENT_FLOW_QUERY_STRING}=${flow}`,
			);
	}, [navigate, view, flow]);

	return (
		<Stack spacing={4}>
			{view && (
				<Tabs value={view} onChange={handleChange} aria-label='basic tabs example'>
					<Tab label='Meeting' value={MeetingOrMoment.MEETING} />
					<Tab label='Moment' value={MeetingOrMoment.MOMENT} />
				</Tabs>
			)}

			{view === MeetingOrMoment.MEETING && <MeetingSelect />}

			{view === MeetingOrMoment.MOMENT && <MomentInput />}
		</Stack>
	);
}
