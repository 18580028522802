import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import type {} from '@mui/x-data-grid/themeAugmentation';

declare module '@mui/material/styles' {
	interface Theme {
		blueGray: {
			900: string;
			800: string;
			700: string;
			600: string;
			400: string;
			300: string;
			200: string;
			100: string;
			50: string;
		};
	}

	interface ThemeOptions {
		blueGray: {
			900: React.CSSProperties['color'];
			800: React.CSSProperties['color'];
			700: React.CSSProperties['color'];
			600: React.CSSProperties['color'];
			400: React.CSSProperties['color'];
			300: React.CSSProperties['color'];
			200: React.CSSProperties['color'];
			100: React.CSSProperties['color'];
			50: React.CSSProperties['color'];
		};
	}
}

const theme = createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 700,
			lg: 900,
			xl: 1200,
		},
	},
	blueGray: {
		900: '#263238',
		800: '#37474F',
		700: '#455A64',
		600: '#546E7A',
		400: '#78909C',
		300: '#90A4AE',
		200: '#B0BEC5',
		100: '#CFD8DC',
		50: '#ECEFF1',
	},
	palette: {
		primary: {
			main: '#BAC53C',
			dark: '#BAC53C',
			light: '#BAC53C',
		},
		secondary: {
			main: '#FE77D6',
			dark: '#FE77D6',
			light: '#FE77D6',
		},
		error: {
			main: red.A400,
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					textTransform: 'uppercase',
					fontFamily: 'Kanit, sans-serif',
					fontWeight: 500,
					fontSize: '14px',
				},
			},
		},
		MuiTypography: {
			defaultProps: {
				whiteSpace: 'pre-line',
			},
			styleOverrides: {
				root: {
					fontFamily: 'Kanit, sans-serif',
				},
				h1: {
					fontStyle: 'normal',
					fontWeight: 500,
					fontSize: '34px',
					lineHeight: '120%',
					letterSpacing: '-0.5px',
					color: '#263238',
				},
				h3: {
					fontWeight: 'normal',
					fontSize: '34px',
				},
				h4: {
					fontWeight: 500,
					fontSize: '20px',
					lineHeight: '123.5%',
				},
				h5: {
					fontStyle: 'normal',
					fontWeight: 400,
					fontSize: '18px',
					lineHeight: '133.4%',
				},
				h6: {
					fontWeight: '500',
					fontSize: '20px',
					lineHeight: '32px',
					letterSpacing: '0.15px',
				},
				body1: {
					fontWeight: 'normal',
					fontSize: '16px',
					lineHeight: '24px',
					letterSpacing: '0.15px',
					fontStyle: 'normal',
				},
				body2: {
					fontWeight: 'normal',
					fontSize: '14px',
					lineHeight: '20px',
					letterSpacing: '0.17px',
				},
				caption: {
					fontWeight: 'normal',
					fontSize: '12px',
					lineHeight: '19.92px',
					letterSpacing: '0.4px',
				},
				overline: {
					fontWeight: 400,
					fontSize: '12px',
					letterSpacing: '1px',
					textTransform: 'uppercase',
				},
				subtitle1: {
					color: '#90A4AE',
				},
			},
		},
		MuiListItemButton: {
			styleOverrides: {
				root: {
					marginTop: '15px',
				},
			},
		},
		MuiDataGrid: {
			styleOverrides: {
				root: {
					border: 0,
					cursor: 'pointer',
					'& .MuiDataGrid-iconSeparator': {
						display: 'none',
					},
					'& .MuiDataGrid-columnHeaders': {
						background: '#F5F5F5',
						border: 'none',
						color: 'rgba(0, 0, 0, 0.6)',
					},
					'& .MuiDataGrid-cell': {
						borderBottom: 'none',
					},
					'& .MuiDataGrid-row.Mui-selected, .MuiDataGrid-row.Mui-selected:hover': {
						backgroundColor: '#F5F5F5',
					},
					'&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
						outline: 'none',
					},
				},
			},
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					'& label.Mui-focused': {
						color: 'rgba(0, 0, 0, 0.6)',
					},
					'& .MuiOutlinedInput-root': {
						'&.Mui-focused fieldset': {
							border: '1px solid rgba(0, 0, 0, 0.6);',
							// borderColor: 'rgba(0, 0, 0, 0.4);',
						},
					},
				},
			},
		},
	},
});

export default theme;
