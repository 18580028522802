import { Alert } from '@mui/material';
import { Skeleton } from './Skeleton';

type RendererProps = {
	isLoading: boolean;
	data: any;
	error: unknown;
	children: JSX.Element;
	empty?: JSX.Element;
	isEmpty?: boolean;
};

export function Renderer({ isLoading, data, children, error, isEmpty, empty }: RendererProps): JSX.Element {
	if (isLoading) return <Skeleton />;
	if (isEmpty) return empty ? empty : data;
	if (data) return children;
	if (error) return <Alert severity='error'>There was an error</Alert>;
	return <></>;
}
