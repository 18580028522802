import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import Markdown from 'markdown-to-jsx';
import { Competency, CreateEvaluationInput, Question } from '../../../common/graphql/types-and-hooks';
import { renderQuestionSelectLabel } from '../../../common/util/format/competency';
import { plural } from '../../../common/util/format/plural';
import { ResponsePageString, useResponse } from '../../../pages/Response';

export function QuestionSelect(): JSX.Element {
	const { request, evaluations, setEvaluations, navigate } = useResponse();
	const {
		requestor: { firstName },
	} = request;

	const allQuestions = request.competencies.flatMap((r) => r.questions);

	function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
		if (evaluations.some((evaluation) => evaluation.question === event.target.id))
			return setEvaluations(evaluations.filter((evaluation) => evaluation.question !== event.target.id));
		else
			setEvaluations(
				evaluations.concat({
					question: allQuestions.find((q) => q._id === event.target.id)?._id,
					competency: request.competencies.find((c) => c.questions.some((q) => q._id === event.target.id))
						?._id,
					score: 0,
					context: '',
				} as CreateEvaluationInput),
			);
	}

	function renderCompetencySection(competency: Competency, index: number): JSX.Element {
		return (
			<Box key={index}>
				<FormControl component='fieldset' variant='standard'>
					<FormLabel focused={false} component='legend'>
						<Typography variant='caption'>{competency.title}</Typography>
					</FormLabel>
					<FormGroup>
						{competency.questions?.map((q, i) => renderCompetencySelect(competency, q, i))}
					</FormGroup>
				</FormControl>
			</Box>
		);
	}

	function renderCompetencySelect(competency: Competency, question: Question, index: number): JSX.Element {
		return (
			<FormControlLabel
				key={index}
				sx={{
					marginY: '10px',
					...(evaluations.some((e) => e.question === question._id) && {
						backgroundColor: 'rgba(254, 119, 214, 0.08)',
					}),

					borderRadius: '4px',
				}}
				control={
					<Checkbox
						id={question._id}
						checked={evaluations.some((e) => e.question === question._id)}
						onChange={handleChange}
						name={question.body}
					/>
				}
				label={<Markdown>{renderQuestionSelectLabel(competency, firstName as string, question.body)}</Markdown>}
			/>
		);
	}

	return (
		<Stack spacing={2}>
			<Typography variant='h1' color='#263238'>
				From the {request.competencies.flatMap((c) => c.questions).length} questions below, select only 1 or 2
				of them to answer about {firstName}.
			</Typography>
			<Typography variant='h5' color='#455A64'>
				{firstName} has chosen the areas of interest they want feedback on -- not the questions. Based on your
				interaction, choose the questions where you have the most relevant insight to share.
			</Typography>

			<Stack spacing={2}>
				{request.competencies?.map((competency, i) => renderCompetencySection(competency, i))}
			</Stack>
			<Button
				disabled={evaluations.length === 0}
				onClick={() => navigate(ResponsePageString.FEEDBACK)}
				color='secondary'
				variant='contained'
				fullWidth
			>
				{`CONTINUE WITH ${evaluations.length} SELECTION${plural(evaluations.length)}`}
			</Button>
		</Stack>
	);
}
