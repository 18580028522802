import { useEffect } from 'react';
import { hotjar } from 'react-hotjar';
import { config, isNotDevelopment } from '../../env-config';

export const Hotjar: React.FC = () => {
	useEffect(() => {
		if (isNotDevelopment && config.hotjarHJID && config.hotjarHJSV) {
			hotjar.initialize(config.hotjarHJID, config.hotjarHJSV);
		}
	}, []);

	return null;
};
