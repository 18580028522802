import { Box, Button, Stack, Typography } from '@mui/material';
import theme from '../../../styles/theme';

type DashboardSectionProps = {
	children: React.ReactNode;
	buttonTitle: string;
	onButtonClick: () => void;
	sectionTitle: string;
};

export function DashboardSection({
	children,
	buttonTitle,
	sectionTitle,
	onButtonClick,
}: DashboardSectionProps): JSX.Element {
	return (
		<Box>
			<Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} mb={1.5}>
				<Typography variant='h4' sx={{ color: theme.blueGray[200] }}>
					{sectionTitle}
				</Typography>
				<Button onClick={onButtonClick} variant='outlined' color='secondary' sx={{ color: '#FFF' }}>
					{buttonTitle}
				</Button>
			</Stack>
			{children}
		</Box>
	);
}
