import axios, { AxiosRequestConfig } from 'axios';
import { config } from '../../env-config';

export function customAxiosFetcher<TData, TVariables>(query: string, variables?: TVariables) {
	return async (): Promise<TData> => {
		const body = JSON.stringify({ query, variables });
		const options: AxiosRequestConfig = {
			headers: { 'Content-Type': 'application/json' },
		};

		const res = await axios.post(config.api, body, options);

		if (res.data.errors?.length) {
			const firstError = res.data.errors[0];
			throw new Error(firstError.message);
		}
		return res.data.data;
	};
}
