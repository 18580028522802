import { useAuth0 } from '@auth0/auth0-react';
import { Button, Chip, Stack, Typography } from '@mui/material';
import Box from '@mui/system/Box';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ExpandableCard } from '../../../common/components/ExpandableCard';
import { UserAvatar } from '../../../common/components/UserAvatar';
import { Event, EventUser, User } from '../../../common/graphql/types-and-hooks';
import { dayMonthDay, time } from '../../../common/util/format/date';
import { fullNameOrEmail } from '../../../common/util/format/name';
import { RequestPageString, useRequest } from '../../../pages/Request';
import { AvailableRoutes } from '../../../pages/routeConfig';

export type MeetingCardProps = {
	event: Event;
};

function EventOrganizer(props: { event: Event }): JSX.Element {
	const { event } = props;
	const { user } = useAuth0();

	const participantOrganizer = event.attendeeUsers.find((au) => au.email === event.organizerEmail);

	if (participantOrganizer)
		return (
			<Typography variant='body2' color='#607D8B'>
				Organizer: {fullNameOrEmail(participantOrganizer as User)}
			</Typography>
		);

	const currentUserIsOrganizer = user?.email === event.organizerEmail;

	if (currentUserIsOrganizer)
		return <Chip sx={{ color: '#09686B', backgroundColor: '#09686B0A' }} label='Organizer'></Chip>;

	return <></>;
}

export function MeetingCard({ event }: MeetingCardProps): JSX.Element {
	const [request, setRequest] = useRequest();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const view = searchParams.get('view');
	const flow = searchParams.get('flow');

	function deleteMoment() {
		const req = { ...request };
		delete req.moment;
		return req
	}

	function handleMeetingSelection(event: Event): void {
		const updatedRequest = request.moment ? deleteMoment() : request;
		setRequest({ ...updatedRequest, event });
		navigate(`${AvailableRoutes.Request}/${RequestPageString.ROLE}?view=${view}&flow=${flow}`);
	}

	function renderAttendeeUserItem(user: EventUser): JSX.Element {
		return (
			<Box key={user.email} sx={{ display: 'flex', alignItems: 'center' }}>
				<UserAvatar user={user as User}></UserAvatar>{' '}
				<Typography sx={{ marginLeft: '10px' }}>{fullNameOrEmail(user as User)}</Typography>
			</Box>
		);
	}

	return (
		<ExpandableCard
			key={event.calendarProvidedId}
			cardActionAreaOnClick={() => handleMeetingSelection(event)}
			mainSection={
				<>
					<Typography sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
						{dayMonthDay(event.start)} from {time(event.start)} to {time(event.end)}
					</Typography>
					<Stack direction='row' justifyContent='space-between'>
						<Typography variant='h5' component='div'>
							{event.title}
						</Typography>
						<EventOrganizer event={event} />
					</Stack>
				</>
			}
			clickToExpandSection={
				<Button variant='text' color='secondary'>
					{event.attendeeUsers.length} {event.attendeeUsers.length > 1 ? 'Participants' : 'Participant'}
				</Button>
			}
			expandSection={
				<>
					<Typography paragraph>Participants:</Typography>
					<Stack spacing={2}>{event.attendeeUsers.map((u: EventUser) => renderAttendeeUserItem(u))}</Stack>
				</>
			}
		></ExpandableCard>
	);
}
