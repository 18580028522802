import { Box, Divider, Stack, TextField, Typography } from '@mui/material';
import Markdown from 'markdown-to-jsx';
import { LoadingButton } from '../../../common/components/LoadingButton';
import {
	CreateEvaluationInput,
	CreateResponseInput,
	useCreateResponseMutation,
} from '../../../common/graphql/types-and-hooks';
import { useSnackbar } from '../../../common/hooks/useSnackbar';
import { SnackbarType } from '../../../common/providers/SnackbarProvider';
import { renderQuestionSelectLabel } from '../../../common/util/format/competency';
import { ResponsePageString, useResponse } from '../../../pages/Response';
import theme from '../../../styles/theme';
import { FeedbackSlider } from './FeedbackSlider';
import { InchyFeedbackTooltip } from './InchyFeedbackTooltip';

export function Feedback(): JSX.Element {
	const { addSnackbar } = useSnackbar();
	const { request, evaluations, updateEvaluation, navigate } = useResponse();
	const mutation = useCreateResponseMutation({
		onSuccess: () => {
			navigate(ResponsePageString.SUCCESS);
		},
		onError: (error: any) => {
			console.error(error);
			addSnackbar({
				message: 'Error creating response! Please try again.',
				type: SnackbarType.Error,
			});
		},
	});

	function lastItem(index: number): boolean {
		return evaluations.length - 1 === index;
	}

	function renderEvaluation(e: CreateEvaluationInput, index: number): JSX.Element {
		const competency = request.competencies.find((c) => c._id === e.competency);
		const title = competency?.title;
		const body = competency?.questions.find((q) => q._id === e.question)?.body;
		return (
			<Box key={index} sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
				<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
					<Typography variant='caption'>{title}</Typography>
				</Box>
				<Typography variant='h3'>
					<Markdown>{renderQuestionSelectLabel(competency!, request.requestor.firstName!, body!)}</Markdown>
				</Typography>
				<FeedbackSlider evaluation={e} score={e.score} updateEvaluation={updateEvaluation} />
				<Typography variant='h5'>Briefly describe an example you saw and the impact it had.</Typography>
				<TextField
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						updateEvaluation(e.question, {
							context: event?.target?.value,
						});
					}}
					placeholder='Is there a specific example you can share? How did it impact the team or project? Any suggestions for next time?'
					multiline
					fullWidth
					rows={4}
				/>
				{!lastItem(index) && <Divider />}
			</Box>
		);
	}

	function handleSubmit() {
		const createEvaluationInputs = evaluations.map((e) => {
			return {
				competency: e.competency,
				context: e.context,
				question: e.question,
				score: e.score,
			} as CreateEvaluationInput;
		});
		const createResponseInput = {
			evaluations: createEvaluationInputs,
			requestId: request._id,
		} as CreateResponseInput;
		mutation.mutate({ payload: createResponseInput });
		navigate(ResponsePageString.SUCCESS);
	}

	return (
		<Box>
			<Typography variant='h1' color={theme.blueGray[900]} mb={2}>
				Give feedback
			</Typography>
			<InchyFeedbackTooltip />
			<Stack spacing={2}>{evaluations.map((e, i) => renderEvaluation(e, i))}</Stack>

			<LoadingButton
				loading={mutation.isLoading}
				sx={{ marginTop: '14px' }}
				onClick={handleSubmit}
				color='secondary'
				variant='contained'
				fullWidth
			>
				SUBMIT
			</LoadingButton>
		</Box>
	);
}
