import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import { Box, CircularProgress, IconButton, Snackbar as MuiSnackbar } from '@mui/material';
import React from 'react';
import { useSnackbar } from '../../hooks/useSnackbar';
import { SnackbarType } from '../../providers/SnackbarProvider';

export default function Snackbar(): JSX.Element {
	const { snackbar, removeSnackbar } = useSnackbar();

	const createSnacks = (): JSX.Element | JSX.Element[] => {
		return (
			<>
				{snackbar && (
					<MuiSnackbar
						open={true}
						anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
						onClose={() => removeSnackbar()}
						key={snackbar.id}
						action={
							<React.Fragment>
								<IconButton
									aria-label='close'
									color='inherit'
									sx={{ p: 0.5 }}
									onClick={() => removeSnackbar()}
								>
									<CloseIcon />
								</IconButton>
							</React.Fragment>
						}
						message={
							<Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
								{snackbar.type === SnackbarType.Loading && (
									<CircularProgress size={20} color='primary' />
								)}
								{snackbar.type === SnackbarType.Success && <CheckCircleOutlineIcon />}

								{snackbar.message}
							</Box>
						}
					></MuiSnackbar>
				)}
			</>
		);
	};

	return <>{createSnacks()}</>;
}
