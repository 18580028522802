import { Stack, Typography } from '@mui/material';
import { BackButton } from '../../../../common/components/BackButton';
import { BottomFixedButtonWrapper } from '../../../../common/components/BottomFixedButtonWrapper';
import { FeedbackButton } from '../../home/FeedbackButton';
import { DashboardFeedbackFromMeList } from './DashboardFeedbackFromMe';

export function DashboardAllFeedbackFromMe() {
	return (
		<Stack spacing={2} py={4}>
			<BackButton />
			<Typography variant='h1' color='#FFF'>
				From me
			</Typography>
			<DashboardFeedbackFromMeList includeViewedResponses={true} />
			<BottomFixedButtonWrapper>
				<FeedbackButton />
			</BottomFixedButtonWrapper>
		</Stack>
	);
}
