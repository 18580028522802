import { Stack } from '@mui/material';
import { EmptyInchE } from '../../../../common/components/EmptyInchE';
import { Renderer } from '../../../../common/components/Renderer';
import { useRequestsImResponderOnQuery } from '../../../../common/graphql/types-and-hooks';
import { FeedbackFromMeCard } from './FeedbackFromMeCard';

type DashboardFeedbackFromMeListProps = {
	includeViewedResponses: boolean;
};
export function DashboardFeedbackFromMeList({ includeViewedResponses }: DashboardFeedbackFromMeListProps): JSX.Element {
	const { data, isLoading, error } = useRequestsImResponderOnQuery({
		includeViewedResponses: includeViewedResponses,
	});

	const content = data?.requestsImResponderOn;
	const isEmpty: boolean | undefined = content && content.length < 1;

	return (
		<Renderer
			isLoading={isLoading}
			data={data}
			error={error}
			isEmpty={isEmpty}
			empty={
				<EmptyInchE
					displayInchE={true}
					title="You're all caught up!"
					body='There are no feedback requests waiting on you.'
				/>
			}
		>
			<Stack spacing={2} width='100%'>
				{content?.map((r) => (
					<FeedbackFromMeCard key={r._id} request={r} />
				))}
			</Stack>
		</Renderer>
	);
}
