import { User } from '../../graphql/types-and-hooks';

export function firstNameOrEmail(user: User): string {
	if (user?.firstName) {
		return user.firstName;
	} else {
		return user?.email;
	}
}

export function nameOrEmailInitials(user: User) {
	if (user?.firstName && user?.lastName) {
		return `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`;
	} else {
		return (user?.email ?? '').substring(0, 2);
	}
}

export function fullName(user: User): string {
	return `${user?.firstName} ${user?.lastName}`;
}

export function fullNameOrEmail(user: User): string {
	if (user?.firstName && user?.lastName) {
		return fullName(user);
	} else {
		return user?.email;
	}
}
