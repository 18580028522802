import { useAuth0 } from '@auth0/auth0-react';
import { Box, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { BottomScrollableFixedButtonWrapper } from '../common/components/BottomScrollableFixedButtonWrapper';
import { DashboardFeedbackFromMeList } from '../features/dashboard/feedback/FeedbackFromMe/DashboardFeedbackFromMe';
import { DashboardFeedbackToMeList } from '../features/dashboard/feedback/FeedbackToMe/DashboardFeedbackToMeList';
import { DashboardSection } from '../features/dashboard/home/DashboardSection';
import { FeedbackButton } from '../features/dashboard/home/FeedbackButton';
import { FeedbackStats } from '../features/dashboard/stats/FeedbackStats';
import { FocusAreas } from '../features/dashboard/stats/FocusAreas';
import { AvailableRoutes } from './routeConfig';

export default function DashboardPage(): JSX.Element {
	const auth0 = useAuth0();
	const navigate = useNavigate();

	return (
		<Stack spacing={2} py={4}>
			<Box textAlign={'left'}>
				<Typography variant='h1' color={'#FFFFFF'} mb={1.5}>
					Welcome, {auth0.user?.given_name}
				</Typography>
				<FeedbackStats />
			</Box>

			<DashboardSection
				sectionTitle={'To me'}
				buttonTitle={'View All'}
				onButtonClick={() => navigate(AvailableRoutes.AllFeedbackToMe)}
			>
				<DashboardFeedbackToMeList includeViewedResponses={false} />
			</DashboardSection>

			<DashboardSection
				sectionTitle={'From me'}
				buttonTitle={'View All'}
				onButtonClick={() => navigate(AvailableRoutes.AllFeedbackFromMe)}
			>
				<DashboardFeedbackFromMeList includeViewedResponses={false} />
			</DashboardSection>
			<DashboardSection
				sectionTitle={'Focus areas'}
				buttonTitle={'Modify'}
				onButtonClick={() => navigate(AvailableRoutes.FocusAreas)}
			>
				<FocusAreas />
			</DashboardSection>

			<BottomScrollableFixedButtonWrapper bottomPixelSpacing={31}>
				<FeedbackButton />
			</BottomScrollableFixedButtonWrapper>
		</Stack>
	);
}
