import { Button, ButtonProps } from '@mui/material';
import { OnboardingButtonTypography } from './OnboardingTypography';

export type OnboardingPrimaryButtonProps = ButtonProps & {
	onClick: () => void;
	buttonText: string;
};

export function OnboardingPrimaryButton({ buttonText, onClick, ...rest }: OnboardingPrimaryButtonProps) {
	return (
		<Button
			variant='contained'
			color='secondary'
			onClick={() => onClick()}
			fullWidth
			sx={{ height: '42px' }}
			{...rest}
		>
			<OnboardingButtonTypography color={'#000'}>{buttonText}</OnboardingButtonTypography>
		</Button>
	);
}
