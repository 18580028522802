import { CardActionArea } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Renderer } from '../../../common/components/Renderer';
import { Role as RoleType, useOrganizationConfigQuery } from '../../../common/graphql/types-and-hooks';
import { RequestPageString, useRequest } from '../../../pages/Request';
import { AvailableRoutes } from '../../../pages/routeConfig';
import { RequestFlow } from '../PersonSelect/PersonSelect';

export function RoleSelect(): JSX.Element {
	const { data, isLoading, error } = useOrganizationConfigQuery();
	const navigate = useNavigate();
	const [request, setRequest] = useRequest();
	const [searchParams] = useSearchParams();
	const view = searchParams.get('view');
	const flow = searchParams.get('flow');

	function handleRoleSelection(role: RoleType): void {
		setRequest({ ...request, role });
		if (flow === RequestFlow.MeetingFirst) {
			navigate(`${AvailableRoutes.Request}/${RequestPageString.PERSON}?view=${view}&flow=${flow}`);
		} else {
			navigate(`${AvailableRoutes.Request}/${RequestPageString.FOCUS_AREA}?view=${view}&flow=${flow}`);
		}
	}

	function renderRoleCard(role: RoleType): JSX.Element {
		return (
			<Card key={role._id} onClick={() => handleRoleSelection(role)}>
				<CardActionArea>
					<CardContent>
						<Typography variant='h5' gutterBottom>
							{role.name}
						</Typography>
						<Typography variant='body1'>{role.description}</Typography>
					</CardContent>
				</CardActionArea>
			</Card>
		);
	}

	return (
		<Renderer isLoading={isLoading} data={data} error={error}>
			<Stack spacing={2}>{data?.organizationConfig.organization.roles.map((c) => renderRoleCard(c))}</Stack>
		</Renderer>
	);
}
