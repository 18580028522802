import { withAuthenticationRequired } from '@auth0/auth0-react';
import { createBrowserRouter } from 'react-router-dom';
import { DashboardRoute } from '../common/components/DashboardRoute';
import { DashboardAllFeedbackFromMe } from '../features/dashboard/feedback/FeedbackFromMe/DashboardAllFeedbackFromMe';
import { DashboardAllFeedbackToMe } from '../features/dashboard/feedback/FeedbackToMe/DashboardAllFeedbackToMe';
import { FocusAreasPage } from '../features/dashboard/focus-areas/FocusAreasPage';
import { CompetencySelect } from '../features/request/CompetencySelect/CompetencySelect';
import { MeetingOrMomentSelect } from '../features/request/MeetingOrMomentSelect/MeetingOrMomentSelect';
import { MeetingOrPersonSelect } from '../features/request/MeetingOrPersonSelect/MeetingOrPersonSelect';
import { PersonSelect } from '../features/request/PersonSelect/PersonSelect';
import { Recap } from '../features/request/Recap/Recap';
import { RoleSelect } from '../features/request/RoleSelect/RoleSelect';
import { Context } from '../features/response/Context/Context';
import { Feedback } from '../features/response/Feedback/Feedback';
import { QuestionSelect } from '../features/response/QuestionSelect/QuestionSelect';
import Success from '../features/response/Success/Success';
import { AppShell } from './AppShell';
import DashboardPage from './Dashboard';
import HomePage from './HomePage';
import OnboardingPage from './Onboarding';
import OnboardingFocusAreaPage from './OnboardingFocusAreaPage';
import OnboardingFocusAreaSelectPage from './OnboardingFocusAreaSelectPage';
import { OnboardingShell } from './OnboardingShell';
import { OnboardingStartRequestingFeedbackPage } from './OnboardingStartRequestingFeedbackPage';
import RequestPage, { RequestPageString } from './Request';
import ResponsePage, { ResponsePageString } from './Response';
import ViewResponsePage from './ViewResponse';

export enum AvailableRoutes {
	Dashboard = '/authed/dashboard',
	AllFeedbackToMe = '/authed/dashboard/all-feedback-to-me',
	AllFeedbackFromMe = '/authed/dashboard/all-feedback-from-me',
	FocusAreas = '/authed/focus-areas',
	Request = '/authed/request',
	RequestStart = '/authed/request/start',
	ViewResponse = '/authed/view-response',
	Response = '/authed/response',
	OnboardingGetStarted = '/onboarding/get-started',
	OnboardingFocusArea = '/onboarding/focus-areas',
	OnboardingSelectFocusArea = '/onboarding/select-focus-area',
	OnboardingStartRequestingFeedback = '/onboarding/start-requesting-feedback',
}

const AuthedAppShell = withAuthenticationRequired(AppShell);
const AuthedOnboardingShell = withAuthenticationRequired(OnboardingShell);

export const router = createBrowserRouter([
	{
		path: '/authed',
		element: <AuthedAppShell />,
		children: [
			{
				path: 'dashboard',
				element: (
					<DashboardRoute>
						<DashboardPage />
					</DashboardRoute>
				),
			},
			{
				path: 'dashboard/all-feedback-to-me',
				element: <DashboardAllFeedbackToMe />,
			},
			{
				path: 'dashboard/all-feedback-from-me',
				element: <DashboardAllFeedbackFromMe />,
			},
			{
				path: 'focus-areas',
				element: <FocusAreasPage />,
			},
			{
				path: 'view-response/:requestId',
				element: <ViewResponsePage />,
			},
			{
				path: 'request',
				element: <RequestPage />,
				children: [
					{
						path: RequestPageString.START,
						element: <MeetingOrPersonSelect />,
					},
					{
						path: RequestPageString.MEETING,
						element: <MeetingOrMomentSelect />,
					},
					{
						path: RequestPageString.PERSON,
						element: <PersonSelect />,
					},
					{
						path: RequestPageString.ROLE,
						element: <RoleSelect />,
					},
					{
						path: RequestPageString.FOCUS_AREA,
						element: <CompetencySelect />,
					},
					{
						path: RequestPageString.RECAP,
						element: <Recap />,
					},
				],
			},
			{
				path: 'response/:requestId',
				element: <ResponsePage />,
				children: [
					{
						path: ResponsePageString.START,
						element: <Context />,
					},
					{
						path: ResponsePageString.QUESTION_SELECT,
						element: <QuestionSelect />,
					},
					{
						path: ResponsePageString.FEEDBACK,
						element: <Feedback />,
					},
					{
						path: ResponsePageString.SUCCESS,
						element: <Success />,
					},
				],
			},
		],
	},
	{
		path: 'onboarding',
		element: <AuthedOnboardingShell />,
		children: [
			{
				path: 'get-started',
				element: <OnboardingPage />,
			},
			{
				path: 'focus-areas',
				element: <OnboardingFocusAreaPage />,
			},
			{
				path: 'select-focus-area',
				element: <OnboardingFocusAreaSelectPage />,
			},
			{
				path: 'start-requesting-feedback',
				element: <OnboardingStartRequestingFeedbackPage />,
			},
		],
	},
	{
		path: '/',
		element: <HomePage />,
	},
	{
		path: '*',
		element: <HomePage />,
	},
]);
