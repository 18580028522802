import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AvailableRoutes } from "../../../pages/routeConfig";

export const FeedbackButton = () => {
	const navigate = useNavigate();
	return (
		<Button
			color='secondary'
			variant='contained'
			fullWidth
			sx={{
				borderRadius: '4px',
				height: '42px',
			}}
			onClick={() => navigate(AvailableRoutes.RequestStart)}
		>
			ASK FOR FEEDBACK
		</Button>
	);
};