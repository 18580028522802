import { SxProps } from '@mui/material';

export const pageStyles: SxProps = {
	flexGrow: 1,
	width: '100%',
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	gap: '20px',
	paddingTop: '10px',
};

export const pageContentStyles: SxProps = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	gap: '10px',
	height: '100%',
	width: '100%',
	marginTop: '12px',
};

export const cardBodyStyles: SxProps = {
	display: 'flex',
	flexDirection: 'column',
	height: '100%',
	backgroundColor: '#FFFFFF',
	borderRadius: '30px 30px 0px 0px',
	padding: '30px',
	flexGrow: 1,
	width: '100%',
};
