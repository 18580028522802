import { CircularProgress } from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';
import { ReactNode } from 'react';

type LoadingButtonProps = ButtonProps & {
	loading: boolean;
	children: ReactNode;
};

export function LoadingButton({ loading, children, ...rest }: LoadingButtonProps): JSX.Element {
	const { startIcon: originalStartIcon } = rest;
	const progressIcon = <CircularProgress color='inherit' size={16} />;

	return (
		<Button {...rest} disabled={loading} startIcon={loading ? progressIcon : originalStartIcon}>
			{children}
		</Button>
	);
}
