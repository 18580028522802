import { Typography, styled } from '@mui/material';

export const OnboardingHeadingTypography = styled(Typography)(() => ({
	fontWeight: 500,
	fontSize: 34,
	color: '#FFF',
	lineHeight: '39.68px',
	letterSpacing: '-1.5px',
}));

export const OnboardingSubHeadingTypography = styled(Typography)(() => ({
	fontWeight: 500,
	fontSize: 20,
	lineHeight: '24.7px',
	letterSpacing: '0.25px',
}));

export const OnboardingBody1Typography = styled(Typography)(() => ({
	fontWeight: 400,
	fontSize: 18,
	lineHeight: '113.4%',
}));

export const OnboardingBody2Typography = styled(Typography)(() => ({
	fontSize: 14,
	fontWeight: 400,
	lineHeight: '22.4px',
	letterSpacing: '0.15px',
}));

export const OnboardingButtonTypography = styled(Typography)(() => ({
	fontSize: 14,
	fontWeight: 500,
	letterSpacing: '0.46px',
}));
