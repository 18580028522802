import { Box, Stack, Typography, styled } from '@mui/material';
import theme from '../../../styles/theme';

const BulletPoint = styled('li')(({ theme }) => ({
	listStyleType: 'disc',
}));

export function InchyFeedbackTooltip() {
	return (
		<Box bgcolor={theme.blueGray[50]} py={2} px={2.5} borderRadius='12px'>
			<Stack direction={'row'} alignItems={'center'} spacing={1.5}>
				<img
					src='/inch-e-small-tooltip.svg'
					alt='Inchy'
					style={{
						backgroundColor: theme.blueGray[900],
						borderRadius: '50px',
						minWidth: '50px',
						minHeight: '50px',
					}}
				/>
				<Typography variant='h4'>A quick note from Inch</Typography>
			</Stack>
			<Typography variant='h5' color={theme.blueGray[900]} mt={1}>
				The most helpful feedback answers these questions:
			</Typography>
			<Box component='ul' sx={{ color: theme.blueGray[600] }} pl={2.5}>
				<BulletPoint>
					<Typography>
						<b>What?</b> Points to a specific behavior to keep doing or change
					</Typography>
				</BulletPoint>
				<BulletPoint>
					<Typography>
						<b>So what?</b> Describes the difference this makes for the individual, team, project, or
						company
					</Typography>
				</BulletPoint>
				<BulletPoint>
					<Typography>
						<b>Now what?</b> May also include your ideas about what to try next
					</Typography>
				</BulletPoint>
			</Box>
			<Typography color={theme.blueGray[600]}>And always aims to help and grow the person!</Typography>
		</Box>
	);
}
