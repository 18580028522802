import { Request } from '../../graphql/types-and-hooks';

export function meetingOrMomentTitle(request: Request): string {
	if (request.event && request.event.title) {
		return request.event.title;
	} else if (request.moment) {
		return request.moment;
	}
	return '';
}

export function meetingOrMomentTitleShort(request: Request): string {
	return meetingOrMomentTitle(request).substring(0, 30);
}
