import { Box, Container } from '@mui/material';
import { useWindowScroll, useWindowSize } from 'react-use';

type BottomScrollableFixedButtonWrapperProps = {
	bottomPixelSpacing?: number;
	children: JSX.Element;
};
export function BottomScrollableFixedButtonWrapper({
	children,
	bottomPixelSpacing,
}: BottomScrollableFixedButtonWrapperProps): JSX.Element {
	const { y } = useWindowScroll();
	const { height: currentWindowHeightPixels } = useWindowSize();

	const documentHeightPixels = document.documentElement.scrollHeight;
	const bufferSpaceToTriggerScroll = 100;

	const isScrollable = documentHeightPixels - bufferSpaceToTriggerScroll > currentWindowHeightPixels;

	if (!isScrollable) return <>{children}</>;

	return (
		<>
			<Box height={bottomPixelSpacing} />
			<Box
				sx={{
					position: 'fixed',
					bottom: '0',
					left: '0',
					zIndex: 9999,
					bgcolor: '#263238',
					transition: 'transform .2s ease-out, opacity .2s ease-out',
					transform: y > 5 ? 'translateY(0)' : 'translateY(50px)',
					opacity: y > 5 ? 1 : 0,
					width: '100%',
				}}
			>
				<Container maxWidth='md' sx={{ my: 2 }}>
					{children}
				</Container>
			</Box>
		</>
	);
}
