import { isNumber } from 'lodash';
import React, { useState } from 'react';
import { v4 } from 'uuid';

export enum SnackbarType {
	Success = 'success',
	Loading = 'loading',
	Info = 'info',
	Error = 'error',
}

export type Snackbar = {
	id?: string;
	message: string;
	type: SnackbarType;
	selfDismissTimeoutSeconds?: number;
};

export type SnackbarContextType = {
	snackbar: Snackbar | null;
	addSnackbar: (snackbar: Snackbar) => Snackbar;
	removeSnackbar: () => void;
};

type SnackbarProviderProps = {
	children: React.ReactNode;
};

export const SnackbarContext = React.createContext<SnackbarContextType>({
	snackbar: {} as Snackbar,
	addSnackbar: (snackbar) => snackbar,
	removeSnackbar: () => {},
});

export default function SnackbarProvider({ children }: SnackbarProviderProps) {
	const [snackbar, setSnackbar] = useState<Snackbar | null>(null);

	const removeSnackbar = () => setSnackbar(null);

	const addSnackbar = (snackbar: Snackbar): Snackbar => {
		const id = v4();
		setSnackbar({ ...snackbar, id } as Snackbar);

		if (snackbar.selfDismissTimeoutSeconds && isNumber(snackbar.selfDismissTimeoutSeconds)) {
			setTimeout(() => {
				removeSnackbar();
			}, snackbar.selfDismissTimeoutSeconds * 1000);
		}

		return snackbar;
	};

	const contextValue = {
		snackbar,
		addSnackbar: (snackbar: Snackbar) => addSnackbar(snackbar),
		removeSnackbar: () => removeSnackbar(),
	};

	return <SnackbarContext.Provider value={contextValue}>{children}</SnackbarContext.Provider>;
}
