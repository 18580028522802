import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetUserByAuth0IdQuery } from '../common/graphql/types-and-hooks';
import { AvailableRoutes } from './routeConfig';

export default function HomePage(): JSX.Element {
	const navigate = useNavigate();
	const { user, loginWithRedirect } = useAuth0();

	const { data } = useGetUserByAuth0IdQuery(
		{},
		{
			enabled: !!user,
		},
	);

	useEffect(() => {
		function navigateToProperStartingPointForUser() {
			const user = data?.getUserByAuth0Id;
			if (user) {
				// if (user.onboarded) navigate("/dashboard/home");
				// else navigate("/onboarding");
				navigate(AvailableRoutes.Dashboard);
			}
		}
		navigateToProperStartingPointForUser();
	}, [data, user, navigate]);

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
			<img alt='inch-logo' src='/white-logo.png' style={{ width: '171px', marginTop: '60px' }}></img>
			<Typography
				variant='h3'
				color={'white'}
				sx={{
					maxWidth: '700px',
					fontWeight: '500',
					fontSize: '34px',
					marginTop: '10px',
					textAlign: 'center',
				}}
			>
				Get timely, bite-size feedback from the right people about the right moments
			</Typography>
			<Box
				sx={{
					marginTop: '20px',
					width: '400px',
					height: '320px',
					position: 'relative',
				}}
			>
				<img
					alt='pink-chat-bubble'
					src='/pink-bubble.png'
					style={{
						width: '195px',
						position: 'absolute',
						left: '0px',
						top: '0px',
					}}
				></img>
				<img
					alt='yellow-chat-bubble'
					src='/yellow-bubble.png'
					style={{
						width: '166px',
						position: 'absolute',
						right: '0px',
						top: '50px',
					}}
				></img>
				<img
					alt='blue-chat-bubble'
					src='/blue-bubble.png'
					style={{
						width: '161px',
						position: 'absolute',
						left: '30px',
						bottom: '0px',
					}}
				></img>
			</Box>
			<Button
				onClick={() => {
					if (!user) {
						loginWithRedirect();
					} else {
						navigate(AvailableRoutes.Dashboard);
					}
				}}
				variant='contained'
				color='secondary'
				sx={{
					marginTop: '25px',
					marginBottom: '85px',
					width: '90%',
					maxWidth: '700px',
					height: '45px',
				}}
			>
				Get Started
			</Button>
		</Box>
	);
}
