import { Stack } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { EmptyInchE } from '../../../../common/components/EmptyInchE';
import { Renderer } from '../../../../common/components/Renderer';
import { useRequestsIveCreatedQuery } from '../../../../common/graphql/types-and-hooks';
import { AvailableRoutes } from '../../../../pages/routeConfig';
import { FeedbackToMeCard } from './FeedbackToMeCard';

type DashboardFeedbackToMeListProps = {
	includeViewedResponses: boolean;
};

export function DashboardFeedbackToMeList({ includeViewedResponses }: DashboardFeedbackToMeListProps): JSX.Element {
	const navigate = useNavigate();

	const isViewAllFeedbackPage = useLocation().pathname === AvailableRoutes.AllFeedbackToMe;
	const { data, isLoading, error } = useRequestsIveCreatedQuery({
		includeViewedResponses: includeViewedResponses,
	});

	const content = data?.requestsIveCreated;
	const isEmpty: boolean | undefined = content && content.length < 1;

	return (
		<Renderer
			isLoading={isLoading}
			data={data}
			error={error}
			isEmpty={isEmpty}
			empty={
				<EmptyInchE
					displayInchE={false}
					title={
						isViewAllFeedbackPage ? 'You have not received any feedback yet!' : 'No new or pending feedback'
					}
					body={isViewAllFeedbackPage ? undefined : 'Get started with your next feedback request!'}
					buttonProps={
						isViewAllFeedbackPage
							? undefined
							: {
									text: 'Ask for feedback',
									onClick: () => navigate(AvailableRoutes.RequestStart),
							  }
					}
				/>
			}
		>
			<Stack spacing={2} width='100%'>
				{content?.map((r) => (
					<FeedbackToMeCard key={r._id} request={r} />
				))}
			</Stack>
		</Renderer>
	);
}
