import { Stack } from '@mui/material';
import MuiSkeleton from '@mui/material/Skeleton';

export function Skeleton(): JSX.Element {
	return (
		<Stack spacing={2} sx={{ width: '100%' }}>
			<MuiSkeleton variant='rounded' width='100%' height={60} />
			<MuiSkeleton variant='rounded' width='100%' height={60} />
			<MuiSkeleton variant='rounded' width='100%' height={60} />
		</Stack>
	);
}
