import { CircularProgress } from '@mui/material';

type LoadingOverlayProps = {
	top?: number;
	left?: number;
	right?: number;
	bottom?: number;
};

const LoadingOverlay = ({ top = 0, left = 0, right = 0, bottom = 0 }: LoadingOverlayProps): JSX.Element => {
	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				position: 'absolute',
				top: top,
				left: left,
				right: right,
				bottom: bottom,
				backgroundColor: 'rgba(255, 255, 255, 0.5)',
				zIndex: 9999,
			}}
		>
			<CircularProgress />
		</div>
	);
};

export default LoadingOverlay;
