import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AvailableRoutes } from '../../pages/routeConfig';

export function BackButton(): JSX.Element {
	const navigate = useNavigate();

	function goBack() {
		if (window.history && window.history.length > 2) {
			navigate(-1);
		} else {
			navigate(AvailableRoutes.Dashboard);
		}
	}

	return (
		<>
			<Button
				color='secondary'
				sx={{ maxWidth: '100px', justifyContent: 'space-between', width: '24px', padding: '0px' }}
				onClick={goBack}
				variant='text'
				startIcon={<ArrowBackIcon />}
			>
				Back
			</Button>
		</>
	);
}
