import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useDashboardStatsQuery } from '../../../common/graphql/types-and-hooks';
import DashboardStatItem from './DashboardStatItem';

export function FeedbackStats() {
	const { data: stats } = useDashboardStatsQuery();

	const shouldRenderStats =
		(stats?.dashboardStats?.requestsMade ?? 0) > 0 || (stats?.dashboardStats?.responsesGiven ?? 0) > 0;

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	if (!shouldRenderStats) return null;

	return (
		<>
			<Typography variant='h4' sx={{ color: '#B0BEC5', marginBottom: '10px' }}>
				Feedback Stats
			</Typography>

			<Stack direction={'row'} spacing={1} justifyContent={isMobile ? 'space-between' : 'initial'}>
				<DashboardStatItem name='to me' value={stats?.dashboardStats.requestsMade}></DashboardStatItem>
				<DashboardStatItem name='from me' value={stats?.dashboardStats.responsesGiven}></DashboardStatItem>
				<DashboardStatItem
					name='time to respond'
					value={`${stats?.dashboardStats.averageTurnaround} hours`}
				></DashboardStatItem>
			</Stack>
		</>
	);
}
