import { Box, Container, Stack, useMediaQuery, useTheme } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useUpdateUserOnboardedStatusMutation } from '../common/graphql/types-and-hooks';
import { useSnackbar } from '../common/hooks/useSnackbar';
import { SnackbarType } from '../common/providers/SnackbarProvider';
import { OnboardingPrimaryButton } from '../features/onboarding/OnboardingPrimaryButton';
import {
	OnboardingBody2Typography,
	OnboardingHeadingTypography,
	OnboardingSubHeadingTypography,
} from '../features/onboarding/OnboardingTypography';
import { AvailableRoutes } from './routeConfig';

export default function OnboardingPage(): JSX.Element {
	const navigate = useNavigate();
	const snackbar = useSnackbar();
	const queryClient = useQueryClient();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const updateUserOnboardedStatus = useUpdateUserOnboardedStatusMutation({
		onSuccess: () => {
			queryClient.invalidateQueries(['getUserByAuth0Id']);
			navigate({
				pathname: AvailableRoutes.OnboardingFocusArea,
			});
		},
		onError: () => {
			console.error('error');
			snackbar.addSnackbar({
				message: 'Error in updating onboarding status! Please try again.',
				type: SnackbarType.Error,
			});
		},
	});

	function handleNextBtn(): void {
		updateUserOnboardedStatus.mutate({});
	}

	const bottomButtonPlaceholder = <Box height={'42px'} mb={3.5} />;

	return (
		<Container
			maxWidth={'md'}
			sx={{
				height: isMobile ? 'calc(100vh - 64px)' : 'auto',
				minHeight: isMobile ? '0px' : '800px',
				maxHeight: '1000px',
				position: 'relative',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: isMobile ? 'space-between' : 'center',
			}}
		>
			<Box>
				<Box height={'256px'} my={'30px'} textAlign={'center'}>
					<img src='/hiya.svg' alt='inch-e-worm-saying-hiya' style={{ position: 'absolute' }} />
					<img
						src='/onboard-welcome.svg'
						alt='inch-e-worm-welcome'
						style={{ backgroundColor: theme.blueGray[800], borderRadius: '50%' }}
					/>
				</Box>
				<Stack spacing={'12px'} mb={'auto'}>
					<OnboardingHeadingTypography>Welcome to Inch!</OnboardingHeadingTypography>
					<OnboardingSubHeadingTypography color={theme.blueGray[50]}>
						Feedback from the right people, at the right time in the right way!
					</OnboardingSubHeadingTypography>
					<OnboardingBody2Typography color={theme.blueGray[100]}>
						The people you interact with during the week are the ones who can provide the best feedback.
						They see the moments where you shine and spot the details where you might need to improve, yet
						their thoughts often go unshared. Inch frees that feedback.
					</OnboardingBody2Typography>
				</Stack>
			</Box>
			<Box sx={{ position: 'absolute', left: 0, bottom: '0px', width: '100%' }}>
				<Container maxWidth={'md'}>
					<OnboardingPrimaryButton onClick={handleNextBtn} buttonText="LET'S GET STARTED" />
					{bottomButtonPlaceholder}
				</Container>
			</Box>
		</Container>
	);
}
