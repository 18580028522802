import { Button, Stack, TextField, Typography } from '@mui/material';
import * as React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { firstNameOrEmail } from '../../../common/util/format/name';
import { RequestPageString, useRequest } from '../../../pages/Request';
import { AvailableRoutes } from '../../../pages/routeConfig';
import { RequestFlow } from '../PersonSelect/PersonSelect';
import { MEETING_OR_MOMENT_FLOW_QUERY_STRING } from './MeetingOrMomentSelect';

export function MomentInput() {
	const navigate = useNavigate();
	const [request, setRequest] = useRequest();
	const [searchParams] = useSearchParams();
	const flow = searchParams.get(MEETING_OR_MOMENT_FLOW_QUERY_STRING);

	function deleteEvent(): void {
		const updatedRequest = { ...request };
		delete updatedRequest.event;
		setRequest(updatedRequest);
	}

	function onContinueBtn(): void {
		if (request.event) {
			deleteEvent();
		}
		navigate(`${AvailableRoutes.Request}/${RequestPageString.ROLE}?flow=${flow}`);
	}

	return (
		<Stack spacing={2}>
			{flow === RequestFlow.MeetingFirst && (
				<Typography variant='h3'>
					Name an interaction that you've had with someone in the past week.{' '}
				</Typography>
			)}
			{flow === RequestFlow.PersonFirst && (
				<Typography variant='h3'>
					Name an interaction that you've had with {firstNameOrEmail(request.responder)} in the past week.{' '}
				</Typography>
			)}
			<TextField
				value={request.moment}
				onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
					setRequest({ ...request, moment: event?.target?.value });
				}}
				placeholder='Give enough detail, so that the person can quickly remember when, where, and what you were talking about.'
				multiline
				fullWidth
				rows={4}
			/>
			<Button
				onClick={() => onContinueBtn()}
				color='secondary'
				variant='contained'
				fullWidth
				disabled={!request.moment?.length}
			>
				Continue
			</Button>
		</Stack>
	);
}
