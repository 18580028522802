import { Stack, Typography } from '@mui/material';
import { BackButton } from '../../../../common/components/BackButton';
import { BottomFixedButtonWrapper } from '../../../../common/components/BottomFixedButtonWrapper';
import { FeedbackButton } from '../../home/FeedbackButton';
import { DashboardFeedbackToMeList } from './DashboardFeedbackToMeList';

export function DashboardAllFeedbackToMe() {
	return (
		<Stack spacing={2} py={4}>
			<BackButton />
			<Typography variant='h1' color='#FFF'>
				To me
			</Typography>
			<DashboardFeedbackToMeList includeViewedResponses={true} />
			<BottomFixedButtonWrapper>
				<FeedbackButton />
			</BottomFixedButtonWrapper>
		</Stack>
	);
}
