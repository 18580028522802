import { Avatar } from '@mui/material';
import { User } from '../graphql/types-and-hooks';
import { nameOrEmailInitials } from '../util/format/name';

type UserAvatarProps = {
	user: User;
};

export function UserAvatar({ user }: UserAvatarProps) {
	const initials = nameOrEmailInitials(user);
	if (user) {
		if (user.profilePhotoUrl) {
			return (
				<>
					<Avatar alt={initials} srcSet={user.profilePhotoUrl} />
				</>
			);
		} else {
			return <Avatar>{initials}</Avatar>;
		}
	}
	return <></>;
}
