import Favorite from '@mui/icons-material/Favorite';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import { Alert, Button, Checkbox, List, ListItem, ListItemText, Snackbar, Stack, Typography } from '@mui/material';
import Box from '@mui/system/Box';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BackButton } from '../../../common/components/BackButton';
import { ExpandableCard } from '../../../common/components/ExpandableCard';
import { Renderer } from '../../../common/components/Renderer';
import {
	Competency,
	useGetUserByAuth0IdQuery,
	useOrganizationConfigQuery,
	useSetMyCompetenciesMutation,
} from '../../../common/graphql/types-and-hooks';
import { AvailableRoutes } from '../../../pages/routeConfig';
import { pageContentStyles, pageStyles } from '../../../styles/common';

type ContinueButtonProps = {
	disabled: boolean;
	onClick: () => void;
	children: JSX.Element;
};
function ContinueButton({ disabled, onClick, children }: ContinueButtonProps): JSX.Element {
	return (
		<Button
			sx={{
				position: 'sticky',
				bottom: '20px',
				marginTop: '40px',
			}}
			disabled={disabled}
			onClick={onClick}
			color='secondary'
			variant='contained'
			fullWidth
		>
			{children}
		</Button>
	);
}

export function FocusAreasPage(): JSX.Element {
	const { data, isLoading, error } = useOrganizationConfigQuery();
	const { data: dbUser } = useGetUserByAuth0IdQuery();
	const navigate = useNavigate();
	const [selectedCompetencies, updateSelectedCompetencies] = useState<Competency[]>([]);
	const [openSnackbar, setOpenSnackbar] = useState<boolean>();
	const setCompetenciesMutation = useSetMyCompetenciesMutation({
		onSuccess: () => {
			navigate(AvailableRoutes.Dashboard);
		},
	});

	useEffect(() => {
		updateSelectedCompetencies(dbUser?.getUserByAuth0Id.competencies as Competency[]);
	}, [dbUser]);

	function handleCompetencySelection(competency: Competency): JSX.Element | void {
		if (!competencyIsChecked(competency) && selectedCompetencies.length === 2) {
			return setOpenSnackbar(true);
		}
		if (competencyIsChecked(competency)) {
			updateSelectedCompetencies(selectedCompetencies.filter((c) => c._id !== competency._id));
		} else {
			updateSelectedCompetencies([...selectedCompetencies, competency]);
		}
	}

	function competencyIsChecked(competency: Competency) {
		return selectedCompetencies.some((c) => c._id === competency._id);
	}

	function renderCompetencyCard(competency: Competency): JSX.Element {
		return (
			<ExpandableCard
				key={competency._id}
				cardActionAreaOnClick={() => {
					handleCompetencySelection(competency);
				}}
				mainSection={
					<>
						<Box sx={{ position: 'relative' }}>
							<Checkbox
								color='secondary'
								sx={{ position: 'absolute', top: '-5px', right: '-5px' }}
								icon={<FavoriteBorder />}
								checkedIcon={<Favorite />}
								disabled={!competencyIsChecked(competency) && selectedCompetencies.length === 2}
								checked={competencyIsChecked(competency)}
							/>
							<Typography variant='h5' component='div'>
								{competency.title}
							</Typography>
							<Typography sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
								{competency.subtitle}
							</Typography>
						</Box>
					</>
				}
				expandSection={
					<>
						<Stack>
							{
								<List dense>
									{competency.details.map((d) => (
										<ListItem key={d}>
											<ListItemText primary={d} />
										</ListItem>
									))}
								</List>
							}
						</Stack>
					</>
				}
				clickToExpandSection={
					<Button variant={'text'} color='secondary'>
						Read More
					</Button>
				}
			/>
		);
	}

	return (
		<Box sx={pageStyles}>
			<Box sx={pageContentStyles}>
				<Box sx={{ width: '100%' }}>
					<BackButton />
					<Typography sx={{ color: '#FFFFFF' }} variant='h1'>
						Select 1-2 areas where you want to focus
					</Typography>
					<Typography variant='body2' sx={{ color: '#CFD8DC' }}>
						You could already be strong in these areas and are looking to improve, or just want to know how
						you’re doing. Don’t worry, you can change these in the future!
					</Typography>
					<Renderer isLoading={isLoading} data={data} error={error}>
						<>
							<Box sx={{ marginTop: '10px', width: '100%' }}>
								<Stack spacing={2}>
									{data?.organizationConfig.competencies?.map((c) => renderCompetencyCard(c))}
								</Stack>
								{selectedCompetencies.length > 0 && (
									<ContinueButton
										disabled={setCompetenciesMutation.isLoading}
										onClick={() => {
											setCompetenciesMutation.mutate({
												payload: { competencies: selectedCompetencies.map((c) => c._id) },
											});
										}}
										children={
											<Typography>
												{selectedCompetencies.length === 0
													? 'Select 1-2 areas where you want to focus'
													: `CONTINUE WITH ${selectedCompetencies.length} SELECTION${
															selectedCompetencies.length > 1 ? 'S' : ''
													  }`}
											</Typography>
										}
									/>
								)}
							</Box>

							<Snackbar
								open={openSnackbar}
								autoHideDuration={6000}
								anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
								onClose={() => setOpenSnackbar(false)}
							>
								<Alert onClose={handleClose} severity='error' sx={{ top: '40px', width: '100%' }}>
									You can only select up to 2 focus areas
								</Alert>
							</Snackbar>
						</>
					</Renderer>
				</Box>
			</Box>
		</Box>
	);

	function handleClose(event: React.SyntheticEvent | Event, reason?: string) {
		if (reason === 'clickaway') return;

		setOpenSnackbar(false);
	}
}
