import { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { AvailableRoutes } from '../../pages/routeConfig';
import { useIsUserOnboarded } from '../hooks/useIsUserOnboarded';
import LoadingOverlay from './feedback/LoadingOverlay';

export function DashboardRoute({ children }: PropsWithChildren): JSX.Element {
	const { isUserOnboarded, isLoading } = useIsUserOnboarded();

	const navbarHeight = 64;
	if (isLoading) return <LoadingOverlay top={navbarHeight} />;

	return isUserOnboarded ? <>{children}</> : <Navigate to={AvailableRoutes.OnboardingGetStarted} replace />;
}
