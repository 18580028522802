import { Box, Container, Stack, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { OnboardingPrimaryButton } from '../features/onboarding/OnboardingPrimaryButton';
import { OnboardingSecondaryButton } from '../features/onboarding/OnboardingSecondaryButton';
import {
	OnboardingBody1Typography,
	OnboardingHeadingTypography,
	OnboardingSubHeadingTypography,
} from '../features/onboarding/OnboardingTypography';
import { AvailableRoutes } from './routeConfig';

export function OnboardingStartRequestingFeedbackPage() {
	const navigate = useNavigate();

	function handleStartRequestingFeedback() {
		navigate({
			pathname: AvailableRoutes.RequestStart,
		});
	}

	function handleSkipForNow() {
		navigate({
			pathname: AvailableRoutes.Dashboard,
		});
	}

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<Container
			maxWidth={'md'}
			sx={{
				height: isMobile ? 'calc(100vh - 64px)' : 'auto',
				minHeight: isMobile ? '0px' : '600px',
				maxHeight: '1000px',
				position: 'relative',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: isMobile ? 'space-between' : 'center',
			}}
		>
			<Box>
				<OnboardingHeadingTypography mt={'28px'}>
					Get started with your first feedback request!
				</OnboardingHeadingTypography>
				<Box color={'#FFF'} mt={3} bgcolor={theme.blueGray[800]} p={'20px'} borderRadius={'12px'}>
					<Stack direction={'row'} alignItems={'center'} spacing={2}>
						<img src='/inch-icon.svg' alt='Inch Logo' />
						<OnboardingSubHeadingTypography color={'#FFF'}>
							A quick note from Inch
						</OnboardingSubHeadingTypography>
					</Stack>
					<OnboardingBody1Typography color={theme.blueGray[100]} pt={1}>
						By inviting people to give you feedback, you are more likely to get the feedback you're wanting.
						Feedback is most effective and easier to deliver when it's based on a specific moment, so Inch
						suggests recent meetings and people you've interacted with. Request feedback now to see how easy
						it is!
					</OnboardingBody1Typography>
				</Box>
			</Box>
			<Box position='absolute' bottom={0} left={0} right={0} bgcolor={theme.blueGray[900]}>
				<Container maxWidth={'md'}>
					<OnboardingPrimaryButton
						onClick={handleStartRequestingFeedback}
						buttonText='START REQUESTING FEEDBACK'
					/>
					<OnboardingSecondaryButton onClick={handleSkipForNow} buttonText='SKIP FOR NOW' />
				</Container>
			</Box>
		</Container>
	);
}
