import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useEffect } from 'react';
import useWindowFocus from 'use-window-focus';

export function AuthTokenLoader(): JSX.Element {
	const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
	const windowFocused = useWindowFocus();

	useEffect(() => {
		async function setTokenInAxios() {
			const token = await getAccessTokenSilently();
			if (token) {
				axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
			}
		}

		if (isAuthenticated) {
			setTokenInAxios();
		}
	}, [isLoading, isAuthenticated, windowFocused, getAccessTokenSilently]);

	return <></>;
}
