import { Box, Button, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import theme from '../../styles/theme';

export type EmptyInchEProps = {
	title: string;
	body?: string;
	displayInchE?: boolean;
	buttonProps?: {
		text: string;
		onClick: () => void;
	};
};

export function EmptyInchE({ title, body, displayInchE, buttonProps }: EmptyInchEProps): JSX.Element {
	return (
		<Stack
			p={4}
			borderRadius={'8px'}
			bgcolor={theme.blueGray[800]}
			minHeight={'180px'}
			justifyContent={'center'}
			alignItems={'center'}
		>
			{displayInchE && (
				<img src='/inch-gray.png' alt='gray inch-e' width='350px' style={{ mixBlendMode: 'hard-light' }}></img>
			)}
			<Typography variant='h4' align='center' color={theme.blueGray[200]}>
				{title}
			</Typography>
			<Typography variant='h5' align='center' color={theme.blueGray[400]}>
				{body}
			</Typography>
			{buttonProps && (
				<Box display='flex' justifyContent='center' mt={1.5}>
					<Button variant='contained' color='secondary' onClick={buttonProps.onClick}>
						{buttonProps.text}
					</Button>
				</Box>
			)}
		</Stack>
	);
}
