import { Box, CardActionArea, Stack, TextField } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Renderer } from '../../../common/components/Renderer';
import { UserAvatar } from '../../../common/components/UserAvatar';
import {
	Event,
	User,
	useEventsForUserQuery,
	useGetActiveUsersInMyOrganizationQuery,
} from '../../../common/graphql/types-and-hooks';
import { fullNameOrEmail } from '../../../common/util/format/name';
import { RequestPageString, useRequest } from '../../../pages/Request';
import { AvailableRoutes } from '../../../pages/routeConfig';
import { MEETING_OR_MOMENT_VIEW_QUERY_STRING, MeetingOrMoment } from '../MeetingOrMomentSelect/MeetingOrMomentSelect';

export enum RequestFlow {
	MeetingFirst = 'meeting-first', // meeting => role => person => focus area => recap
	PersonFirst = 'person-first', // person => meeting => role => focus area => recap
}

export function PersonSelect(): JSX.Element {
	const { data, isLoading, error } = useGetActiveUsersInMyOrganizationQuery();
	const [users, setUsers] = useState<User[]>();
	const { data: events } = useEventsForUserQuery();
	const navigate = useNavigate();
	const [request, setRequest] = useRequest();
	const [searchParams] = useSearchParams();
	const flow = searchParams.get('flow');

	function handlePersonSelection(user: User) {
		setRequest({ ...request, responder: user });

		if (flow === RequestFlow.MeetingFirst) {
			const requestFlowMeetingFirstURL = {
				pathname: `${AvailableRoutes.Request}/${RequestPageString.FOCUS_AREA}`,
				search: `${MEETING_OR_MOMENT_VIEW_QUERY_STRING}=${MeetingOrMoment.MEETING}&flow=${flow}`,
			};
			navigate(requestFlowMeetingFirstURL);
		} else {
			const requestFlowPersonFirstURL = {
				pathname: `${AvailableRoutes.Request}/${RequestPageString.MEETING}`,
				search: `${MEETING_OR_MOMENT_VIEW_QUERY_STRING}=${MeetingOrMoment.MEETING}&flow=${flow}`,
			};
			navigate(requestFlowPersonFirstURL);
		}
	}

	function lookupNumberOfMeetingsWithUser(user: User, events: Event[] | undefined): number | string {
		if (!events) {
			return '...';
		}
		return events.filter((e: Event) => e.attendeeUsers.some((a) => a.email === user.email)).length;
	}

	function filterUsersWhoWereInMeeting(users: User[], event: Event): User[] {
		return users?.filter((u: User) => event.attendeeUsers.some((a) => a.email === u.email));
	}

	function onSearchInputChange(value: string) {
		const regex = new RegExp(value, 'gi');
		setUsers(
			data?.getActiveUsersInMyOrganization.filter((user) => {
				return fullNameOrEmail(user).match(regex);
			}),
		);
	}

	function renderPersonCard(user: User): JSX.Element {
		return (
			<Card key={user._id} onClick={() => handlePersonSelection(user)}>
				<CardActionArea>
					<CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
						<Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
							<UserAvatar user={user} />
							<Typography variant='h5'>
								{user.firstName} {user.lastName}
							</Typography>
						</Box>
						<Typography variant='body1'>
							{lookupNumberOfMeetingsWithUser(user, events?.eventsForUser)} meetings in the past 2 weeks
						</Typography>
					</CardContent>
				</CardActionArea>
			</Card>
		);
	}

	useEffect(() => {
		if (!events) return;
		setUsers(
			data?.getActiveUsersInMyOrganization.sort((a: User, b: User) => {
				const userAMeetingCount = lookupNumberOfMeetingsWithUser(a, events?.eventsForUser) as number;
				const userBMeetingCount = lookupNumberOfMeetingsWithUser(b, events?.eventsForUser) as number;
				return userBMeetingCount - userAMeetingCount;
			}),
		);
	}, [data?.getActiveUsersInMyOrganization, events]);

	if (flow === RequestFlow.MeetingFirst) {
		return (
			<Renderer isLoading={isLoading} data={data} error={error}>
				<Stack spacing={4}>
					{request.event && request.event.attendeeUsers.length > 0 && (
						<Stack spacing={2}>
							<Typography variant='h4'>Participants of {request.event.title}</Typography>
							{filterUsersWhoWereInMeeting(
								data?.getActiveUsersInMyOrganization as User[],
								request.event,
							)?.map((u: User) => renderPersonCard(u))}
						</Stack>
					)}

					<Stack spacing={2}>
						{request.event && <Typography variant='h4'>All (Just in case they popped in)</Typography>}
						<TextField
							label='Search People'
							variant='filled'
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
								onSearchInputChange(event.target.value);
							}}
						></TextField>
						{users?.map((u: User) => renderPersonCard(u))}
					</Stack>
				</Stack>
			</Renderer>
		);
	} else {
		return (
			<Renderer isLoading={isLoading} data={data} error={error}>
				<Stack spacing={2}>
					<TextField
						label='Search People'
						variant='filled'
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							onSearchInputChange(event.target.value);
						}}
					></TextField>
					{users?.map((u: User) => renderPersonCard(u))}
				</Stack>
			</Renderer>
		);
	}
}
