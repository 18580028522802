import { Box, Container, Stack, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { OnboardingPrimaryButton } from '../features/onboarding/OnboardingPrimaryButton';
import { OnboardingSecondaryButton } from '../features/onboarding/OnboardingSecondaryButton';
import { OnboardingBody1Typography, OnboardingHeadingTypography } from '../features/onboarding/OnboardingTypography';
import { AvailableRoutes } from './routeConfig';

export default function OnboardingFocusAreaPage(): JSX.Element {
	const navigate = useNavigate();
	const theme = useTheme();
	const isScreenWide = useMediaQuery(theme.breakpoints.up('md'));

	function handleSelectFocusAreas(): void {
		navigate({
			pathname: AvailableRoutes.OnboardingSelectFocusArea,
		});
	}

	function handleSkipForNow(): void {
		navigate({
			pathname: AvailableRoutes.OnboardingStartRequestingFeedback,
		});
	}

	return (
		<>
			<Stack justifyContent={'space-between'} height={'calc(100vh - 64px)'}>
				<Container maxWidth={'md'}>
					<Stack spacing={'12px'} mt={'28px'}>
						<OnboardingHeadingTypography>
							Tell your team where you want to get better
						</OnboardingHeadingTypography>
						<OnboardingBody1Typography color={theme.blueGray[100]}>
							Inch uses a database of scientifically validated competencies which you can choose as focus
							areas. Choosing your focus areas makes it possible for you to get the feedback you most want
							and makes giving useful feedback to you much easier and faster for people.
						</OnboardingBody1Typography>
					</Stack>
				</Container>

				<Box
					flex={1}
					position='relative'
					width='100%'
					overflow='hidden'
					mt={isScreenWide ? 0 : -3}
					sx={{
						'&::before': {
							content: '""',
							position: 'absolute',
							top: 0,
							left: 0,
							width: '100%',
							height: '100%',
							zIndex: 1,
							background: `linear-gradient(360deg, ${theme.blueGray[900]} 0%, rgba(38, 50, 56, 0) 100%)`,
						},
					}}
				>
					<img
						src='/onboarding-cards.svg'
						alt='inch-e-worm-welcome'
						style={{
							position: 'absolute',
							top: 0,
							left: isScreenWide ? -150 : 0,
							width: '100%',
							height: '100%',
							objectFit: isScreenWide ? 'fill' : 'cover',
							objectPosition: 'top',
						}}
					/>
				</Box>

				<Container maxWidth={'md'}>
					<OnboardingPrimaryButton onClick={handleSelectFocusAreas} buttonText={'SELECT MY FOCUS AREAS'} />
					<OnboardingSecondaryButton onClick={handleSkipForNow} buttonText={'SKIP FOR NOW'} />
				</Container>
			</Stack>
		</>
	);
}
