import { Box, Typography } from '@mui/material';
import theme from '../../../styles/theme';

type DashboardStatItemProps = {
	name: string;
	value?: string | number;
};

export default function DashboardStatItem({ name, value }: DashboardStatItemProps): JSX.Element {
	return (
		<Box border={`1px solid ${theme.blueGray[700]}`} borderRadius={'4px'} p={2}>
			<Typography color={'#FFF'} fontSize='28px' lineHeight={'33.6px'} letterSpacing={'-0.5px'}>
				{value}
			</Typography>
			<Typography color={theme.blueGray[300]} variant='subtitle1'>
				{name}
			</Typography>
		</Box>
	);
}
