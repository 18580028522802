import { Auth0Provider } from '@auth0/auth0-react';
import { CacheProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-license-pro';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { RouterProvider } from 'react-router-dom';
import { AuthTokenLoader } from './common/auth/AuthTokenLoader';
import { Hotjar } from './common/components/Hotjar';
import Snackbar from './common/components/feedback/Snackbar';
import SnackbarProvider from './common/providers/SnackbarProvider';
import createEmotionCache from './common/util/createEmotionCache';
import { config } from './env-config';
import { router } from './pages/routeConfig';
import './styles/globals.css';
import theme from './styles/theme';

const queryClient = new QueryClient();
const clientSideEmotionCache = createEmotionCache();

LicenseInfo.setLicenseKey(config.muiLicenseKey);

const MyApp = () => {
	return (
		<>
			<Hotjar />
			<CacheProvider value={clientSideEmotionCache}>
				<Auth0Provider
					domain={config.auth0Domain}
					clientId={config.auth0ClientId}
					redirectUri={config.auth0RedirectUri}
					audience={config.auth0Audience}
					cacheLocation='localstorage'
					prompt='login'
				>
					<QueryClientProvider client={queryClient}>
						<AuthTokenLoader />
						<ReactQueryDevtools initialIsOpen={false} position={'bottom-right'} />
						<StyledEngineProvider injectFirst>
							<ThemeProvider theme={theme}>
								<SnackbarProvider>
									<CssBaseline enableColorScheme />
									<RouterProvider router={router} />
									<Snackbar />
								</SnackbarProvider>
							</ThemeProvider>
						</StyledEngineProvider>
					</QueryClientProvider>
				</Auth0Provider>
			</CacheProvider>
		</>
	);
};

export default MyApp;
