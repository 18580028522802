import { Box, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { BackButton } from '../common/components/BackButton';
import { Renderer } from '../common/components/Renderer';
import { CreateEvaluationInput, Request, useRequestQuery } from '../common/graphql/types-and-hooks';
import { lastPathOfRoute } from '../common/util/route';
import { RequestContextDrawer } from '../features/response/RequestContextDrawer';
import { cardBodyStyles, pageContentStyles, pageStyles } from '../styles/common';
import { AvailableRoutes } from './routeConfig';

export enum ResponsePageString {
	START = 'start',
	QUESTION_SELECT = 'question-select',
	FEEDBACK = 'feedback',
	SUCCESS = 'success',
}

type ResponsePageConfig = {
	id: ResponsePageString;
	title?: string;
	subtitle?: string;
};

export type EvaluationKeyValuePair = {
	[key: string]: any; // need to type keyof Response
};

type ResponseFormContext = {
	request: Request;
	evaluations: CreateEvaluationInput[];
	setEvaluations: Dispatch<SetStateAction<CreateEvaluationInput[]>>;
	updateEvaluation: (evaluationQuestionId: string, change: EvaluationKeyValuePair) => void;
	navigate: (page: ResponsePageString) => void;
};

function pages(request: Request | undefined): ResponsePageConfig[] {
	if (!request) {
		return [];
	}

	const stringVariant = request.event ? `in ${request.event?.title}` : `during ${request.moment}`;
	return [
		{
			id: ResponsePageString.START,
			title: `${request.requestor.firstName} wants your feedback!`,
		},
		{
			id: ResponsePageString.QUESTION_SELECT,
			title: `You're giving feedback on: `,
			subtitle: `${request.requestor.firstName}'s role as ${request.role.name} ${stringVariant}`,
		},
		{
			id: ResponsePageString.FEEDBACK,
			title: `You're giving feedback on: `,
			subtitle: `${request.requestor.firstName}'s role as ${request.role.name} ${stringVariant}`,
		},
		{
			id: ResponsePageString.SUCCESS,
		},
	];
}

export function useResponse() {
	return useOutletContext<ResponseFormContext>();
}

export default function ResponsePage(): JSX.Element {
	const { requestId } = useParams();
	const { data: request, isLoading, isError } = useRequestQuery({ _id: requestId as string });

	const [evaluations, setEvaluations] = useState<CreateEvaluationInput[]>([]);
	const navigate = useNavigate();
	const location = useLocation();
	const startPage = pages(request?.request).find((p) => p.id === ResponsePageString.START);
	const [pageLookup, setPageLookup] = useState<ResponsePageConfig>(startPage!);

	function localNavigate(page: ResponsePageString) {
		navigate(`${AvailableRoutes.Response}/${requestId}/${page}`);
	}
	const localNavigateCallback = useCallback(localNavigate, [navigate, requestId]);

	function updateEvaluation(evaluationQuestionId: string, change: EvaluationKeyValuePair): void {
		const evaluationIndex = evaluations.map((e) => e.question).indexOf(evaluationQuestionId);
		const evaluation = evaluations[evaluationIndex];
		const updatedEvaluation = { ...evaluation, ...change };
		evaluations.splice(evaluationIndex, 1, updatedEvaluation);
		setEvaluations(evaluations);
	}

	useEffect(() => {
		if (!request?.request) {
			return;
		}
		function lookupCurrentPageToRender(): ResponsePageConfig | undefined {
			const page = lastPathOfRoute(location.pathname);
			if (
				![ResponsePageString.START, ResponsePageString.QUESTION_SELECT].includes(page as ResponsePageString) &&
				isEmpty(evaluations)
			) {
				localNavigateCallback(ResponsePageString.START);
				return;
			}
			const foundPage = pages(request?.request).find((p) => p.id === page);
			if (!foundPage) {
				localNavigateCallback(ResponsePageString.START);
				return;
			}
			setPageLookup(foundPage!);
		}
		lookupCurrentPageToRender();
	}, [location, request, evaluations, localNavigateCallback]);

	const isQuestionSelectPage = useLocation().pathname.includes(ResponsePageString.QUESTION_SELECT);
	const isFeedbackPage = useLocation().pathname.includes(ResponsePageString.FEEDBACK);

	return (
		<Box sx={pageStyles}>
			<Renderer data={request} isLoading={isLoading} error={isError}>
				<Box sx={pageContentStyles}>
					<Box
						sx={{
							width: '100%',
							display: 'flex',
							flexDirection: 'column',
							gap: '10px',
						}}
					>
						{pageLookup?.id !== ResponsePageString.SUCCESS && <BackButton></BackButton>}
						<Typography variant='h1' sx={{ color: '#FFFFFF' }}>
							{pageLookup?.title}
						</Typography>
						<Typography variant='body2' sx={{ color: '#CFD8DC' }}>
							{pageLookup?.subtitle}
						</Typography>
					</Box>
					{request && request.request.context && (isQuestionSelectPage || isFeedbackPage) && (
						<RequestContextDrawer request={request.request} />
					)}
					<Box sx={cardBodyStyles}>
						<Outlet
							context={{
								request: request?.request,
								evaluations,
								setEvaluations,
								updateEvaluation,
								navigate: localNavigateCallback,
							}}
						></Outlet>
					</Box>
				</Box>
			</Renderer>
		</Box>
	);
}
